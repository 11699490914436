import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { EndorsementsField, EndorsementsIdaho, EndorsementsLogo } from '../../assets/Images';
import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import ReviewLinks from '../Home/components/ReviewLinks';
import EndorsementHeading from './EndorsementHeading';
import EndorsementParagraph from './EndorsementParagraph';
import EndorsementVideo from './EndorsementVideo';

const EndorsementLike = () => {
  return (
    <>
      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={12}
      >
        <Flex
          bg="#599854"
          w={{
            base: '100%',
            lg: '60%',
          }}
          flexDir="column"
          py={10}
          ps={10}
          pe={5}
          pos="relative"
          zIndex={1}
        >
          <Image src={EndorsementsLogo} w={220} pos="absolute" left={8} top="-24px" />
          <EndorsementHeading
            heading="they loved working with our team"
            color="#F4C53E"
            textAlign="left"
            mt={16}
          />
          <EndorsementParagraph
            color="white"
            paragraph="Our team lives in Idaho so talking to you is the most exciting part of their day."
          />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
        >
          <EndorsementVideo name="Montage-CustomerService" />
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column-reverse',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={8}
        pos="relative"
        zIndex={5}
      >
        <Flex
          justifyContent="center"
          w={{
            base: '100%',
            lg: '60%',
          }}
          mt={{
            base: 0,
            lg: '-112px',
          }}
          pos="relative"
          zIndex={2}
        >
          <Box
            w={{
              base: '100%',
              lg: '75%',
            }}
            bg="#FFF"
            p={3}
            borderRadius={8}
            boxShadow="0 0 10px rgba(0,0,0,0.18)"
            mt={{
              base: 8,
              lg: 0,
            }}
          >
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SKIP THE DEALERSHIP" />
          </Box>
        </Flex>
      </Flex>

      <Flex justifyContent="center" flex={1} w="100%" mt="-145px" pos="relative" overflow="hidden">
        <Flex pos="absolute" zIndex={4} left={0} top={0} right={0}>
          <Box
            w="80%"
            pt="180px"
            ps={24}
            pos="relative"
            zIndex={2}
            display={{
              base: 'none',
              lg: 'flex',
            }}
          >
            <EndorsementHeading textAlign="left" heading="talk directly to our idaho-based team." />
            <EndorsementParagraph
              pe={72}
              paragraph="Imagine calling a customer lservice line and actually talking to a real person. That's how we do it at Lease End. Our Idaho-based customer service team is ready and waiting to take your lease off your hands and put money back in your pocket by finding you the best loan financing possible."
            />
          </Box>
          <Image src={EndorsementsIdaho} w="50%" pos="absolute" right="-10%" top="-2%" zIndex={1} />
        </Flex>
        <Image src={EndorsementsField} w="100%" pos="relative" zIndex={3} />
      </Flex>
      <Flex
        w="100%"
        pos="relative"
        zIndex={2}
        p={8}
        display={{
          base: 'flex',
          lg: 'none',
        }}
        flexDir="column"
      >
        <EndorsementHeading textAlign="center" heading="talk directly to our idaho-based team." />
        <EndorsementParagraph
          textAlign="center"
          paragraph="Imagine calling a customer lservice line and actually talking to a real person. That's how we do it at Lease End. Our Idaho-based customer service team is ready and waiting to take your lease off your hands and put money back in your pocket by finding you the best loan financing possible."
        />
      </Flex>
      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        bg="#675395"
      >
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
        >
          <EndorsementVideo name="Jeanette-CustomerService" />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
          p={8}
        >
          <EndorsementHeading heading="people really like us." textAlign="left" color="white" />
          <EndorsementParagraph
            color="white"
            fontWeight={500}
            fontSize={18}
            lineHeight={1.4}
            mt={4}
            paragraph="Our customers say we're excellent, and to that we say,'Aw, shucks, guys! We're just doing our job.'"
          />
          <EndorsementParagraph
            color="white"
            fontWeight={500}
            fontSize={18}
            lineHeight={1.4}
            mt={8}
            paragraph="At Lease End we take customer satisfaction seriously. We're all about making your experience quick, friendly and stress free. By the time you wrap up your lease buyout, we want our customesr aying, 'Wow, that was the easiest car purchase of my life!' - which is literally something our lieteral customer Shonda S. said in a review. Thanks Shonda!"
          />
        </Flex>
      </Flex>
      <ReviewLinks m={0} pb={4} pt={8} background="#e0d8f1" />
    </>
  );
};

export default EndorsementLike;
