import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ServerError } from '@apollo/client/link/utils';
import { createStandaloneToast } from '@chakra-ui/react';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { OperationDefinitionNode } from 'graphql';
import { createClient } from 'graphql-ws';
import React, { ReactNode, useEffect, useState } from 'react';

import customTheme from '../chakra';
import config from '../config';
import { totalCompletedDeals } from '../gql/dealGql';
import { paymentEstimate } from '../gql/paymentEstimateGql';
import { payoffQuery } from '../gql/prs/payoffGql';
import { CookieKeys, getCookie, useCookie } from '../hooks/useCookie';
import { logout } from '../services/auth0';
import { handleErrorWithSentry } from '../services/sentry';
import { createErrorToast } from '../utils/toast';

const GraphQLEndpoints = {
  LE: 'le',
  PRS: 'prs',
} as const;

const allowedErrorPathsAndOperations: (string | number)[] = [
  ...[totalCompletedDeals, payoffQuery, paymentEstimate].map(
    (operation) =>
      (operation?.definitions[0] as OperationDefinitionNode)?.name?.value as string | number,
  ),
];

const allowErrors = (path: readonly (string | number)[] | undefined): boolean | undefined =>
  path?.some((p) => allowedErrorPathsAndOperations.includes(p));

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {},
    },
    deals: {
      keyFields: ['id', 'isCobuyer'],
    },
  },
});

const { toast } = createStandaloneToast({ theme: customTheme });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { isErrorHandled } = operation.getContext();
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      handleErrorWithSentry(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${JSON.stringify(
          extensions,
        )}${isErrorHandled ? ' (handled)' : ''}`,
      );

      if (extensions && extensions.code === 'UNAUTHENTICATED') {
        logout();
      } else if (!allowErrors(path) && !isErrorHandled) {
        toast(createErrorToast({ errorMessage: message }));
      }
    });
  }

  if (networkError) {
    const { message } = networkError;

    handleErrorWithSentry(
      `[Network error] ${networkError}: Message: ${message}, Operation: ${
        operation.operationName
      }, Path: ${window.location}${isErrorHandled ? ' (handled)' : ''}`,
      networkError.stack,
    );

    if ((networkError as ServerError).statusCode === 401) {
      logout();
    } else if (!allowErrors([window.location.pathname]) && !isErrorHandled) {
      toast(createErrorToast({ errorMessage: message }));
    }
  }
});

const getAuthHeader = () => {
  const accessToken = getCookie<string>(CookieKeys.ACCESS_TOKEN);
  const guid = getCookie<string>(CookieKeys.GUID_KEY);
  return {
    ...(accessToken && {
      authorization: `Bearer ${accessToken}`,
    }),
    ...(guid && {
      guid,
    }),
  };
};

export const ResetWsContext = React.createContext<{ resetWebSocket: () => void }>({
  resetWebSocket: () => undefined,
});

const apolloClient = new ApolloClient({
  cache,
  connectToDevTools: true,
});

const removeTypenameLink = removeTypenameFromVariables();

const AuthorizedApolloProvider = ({ children }: { children: ReactNode }) => {
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const [resetWebSocket, setResetWebSocket] = useState<() => void>();

  useEffect(() => {
    const ws = new GraphQLWsLink(
      createClient({
        url: `${config.urls.wsRoot}/graphql`,
        connectionParams: async () => {
          return getAuthHeader();
        },
      }),
    );

    const multiApiLink = new MultiAPILink({
      endpoints: {
        [GraphQLEndpoints.LE]: config.urls.apiRoot,
        [GraphQLEndpoints.PRS]: config.urls.prsApiRoot,
      },
      createWsLink: () => ws,
      defaultEndpoint: GraphQLEndpoints.LE,
      getContext: (_, getCurrentContext) => ({
        headers: { ...getCurrentContext().headers, ...getAuthHeader() },
      }),
      createHttpLink: () => createHttpLink(),
    });

    apolloClient.setLink(errorLink.concat(removeTypenameLink.concat(multiApiLink)));
    setResetWebSocket(() => () => ws.client.terminate());
  }, [guid, accessToken]);

  return apolloClient && resetWebSocket ? (
    <ApolloProvider client={apolloClient}>
      <ResetWsContext.Provider value={{ resetWebSocket }}>{children}</ResetWsContext.Provider>
    </ApolloProvider>
  ) : (
    <></>
  );
};

export default AuthorizedApolloProvider;
export { apolloClient };
