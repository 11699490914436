import { useState } from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  DownloadArrowBlue,
  FactSheet,
  GreenCircleMountain,
  Logo,
  ManWithLoudSpeaker,
  OurTeam,
} from '../assets/Images';
import { DarkLink } from '../components/DarkLink';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import Articles from '../components/ui/articles/Articles';
import Card from '../components/ui/views/Card';
import { CONTACT_US_EMAIL } from '../constants/contact';
import useDevice, { DevicesEnum } from '../hooks/useDevice';
import PressMobile from './PressMobile';

interface IStyledSubtitleProps {
  centered?: boolean;
  size?: 'small' | 'large';
  bold?: boolean;
}

export const StyledSubtitle = styled(Subtitle)<IStyledSubtitleProps>`
  font-size: ${(props) =>
    props.size === 'small' ? '14px' : props.size === 'large' ? '24px' : '18px'};
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  margin: 0;
  padding: 0;
  width: ${(props) => (props.centered ? '100%' : '80%')};
  font-weight: ${({ theme, bold }) => (bold ? theme.fontWeights.medium : '')};
`;

export const ArticleTitle = styled(StyledSubtitle)`
  padding-bottom: 1rem;
`;

export const TwoLinesSubtitle = styled(StyledSubtitle)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledContainer = styled(Container)`
  padding-left: 100px;
  padding-right: 100px;
`;

const StyledCard = styled(Card)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
`;

const StyledAnchor = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DownloadLabel = styled.span`
  color: ${({ theme }) => theme.colors.royalBlue};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

interface ISpacerProps {
  size: number;
}

const Spacer = styled.div<ISpacerProps>`
  margin-top: ${({ size }) => (size ? `${size}px` : '0px')};
`;

const InvisibleWhenSmallDiv = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  @media (max-width: 1024px) {
    width: 100px;
  }
`;

const Press = () => {
  const [ourTeamIsHovered, setOurTeamIsHovered] = useState<boolean>(false);
  const [factSheetIsHovered, setFactSheetIsHovered] = useState<boolean>(false);
  const [brandAssetsIsHovered, setBrandAssetsIsHovered] = useState<boolean>(false);

  const device = useDevice();
  const isMobile = device === DevicesEnum.mobile || device === DevicesEnum.tablet;

  return isMobile ? (
    <PressMobile />
  ) : (
    <StyledContainer textAlign="left">
      <Spacer size={10} />
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <InvisibleWhenSmallDiv>
              <Image src={ManWithLoudSpeaker} alt="Man with loud speaker" />
            </InvisibleWhenSmallDiv>
          </Grid.Column>

          <Grid.Column width={13}>
            <Grid.Row stretched>
              <Title fontSize={{ base: '36px', md: '52px' }}>Press Resources</Title>
              <Spacer size={15} />
              <StyledSubtitle size="small" margin={0}>
                Below are some helpful links and downloads for you to help spread the word about us.
                Don’t see what you are looking for?{' '}
                <DarkLink href={`mailto: ${CONTACT_US_EMAIL}`}>Contact us</DarkLink> and we’ll help
                with anything you need.
              </StyledSubtitle>
              <Spacer size={25} />
              <Grid stackable stretched>
                <Grid.Row stretched>
                  <Grid.Column width={5}>
                    <StyledCard>
                      <StyledAnchor
                        href="https://leaseend-com-images.s3.us-east-2.amazonaws.com/BiosAndHeadshots.zip"
                        onMouseOver={() => setOurTeamIsHovered(true)}
                        onMouseLeave={() => setOurTeamIsHovered(false)}
                      >
                        {!ourTeamIsHovered ? (
                          <>
                            <img src={OurTeam} alt="" width={125} />
                          </>
                        ) : (
                          <>
                            <img src={DownloadArrowBlue} alt="" height={110} />
                            <DownloadLabel>DOWNLOAD</DownloadLabel>
                          </>
                        )}
                      </StyledAnchor>
                    </StyledCard>
                    <StyledSubtitle centered>Our Team</StyledSubtitle>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <StyledCard>
                      <StyledAnchor
                        href="https://leaseend-com-images.s3.us-east-2.amazonaws.com/LeaseEndFactSheet.pdf"
                        onMouseOver={() => setFactSheetIsHovered(true)}
                        onMouseLeave={() => setFactSheetIsHovered(false)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {!factSheetIsHovered ? (
                          <>
                            <img src={FactSheet} alt="" height={130} />
                          </>
                        ) : (
                          <>
                            <img src={DownloadArrowBlue} alt="" height={110} />
                            <DownloadLabel>DOWNLOAD</DownloadLabel>
                          </>
                        )}
                      </StyledAnchor>
                    </StyledCard>
                    <StyledSubtitle centered>Fact Sheet</StyledSubtitle>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <StyledCard>
                      <StyledAnchor
                        href="https://leaseend-com-images.s3.us-east-2.amazonaws.com/LeaseEndMediaResources.zip"
                        onMouseOver={() => setBrandAssetsIsHovered(true)}
                        onMouseLeave={() => setBrandAssetsIsHovered(false)}
                      >
                        {!brandAssetsIsHovered ? (
                          <>
                            <img src={GreenCircleMountain} alt="" width={60} />
                            <StyledImage src={Logo} alt="" width={150} />
                          </>
                        ) : (
                          <>
                            <img src={DownloadArrowBlue} alt="" height={110} />
                            <DownloadLabel>DOWNLOAD</DownloadLabel>
                          </>
                        )}
                      </StyledAnchor>
                    </StyledCard>
                    <StyledSubtitle centered>Brand Assets</StyledSubtitle>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Articles />

      <Grid>
        <Grid.Row>
          <Grid.Column width={3} />
          <Grid.Column width={13}>
            <Grid.Row stretched>
              <Title as="h2">Our Mission</Title>
              <Spacer size={15} />
              <StyledSubtitle margin={0}>
                Lease End empowers auto lease owners with the technology to easily exit their lease.
              </StyledSubtitle>
              <Spacer size={15} />
              <StyledSubtitle margin={0} size="small">
                Our mission is to simplify the lease-end process and provide lease owners with the
                resources necessary to make an end-of-lease decision that’s truly best for them.
                Driven by innovative technology, we took the archaic lease-end process dealerships
                offer and turned it into a transparent, accessible solution that’s more intuitive to
                a modern consumer.
              </StyledSubtitle>
              <Spacer size={50} />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledContainer>
  );
};

export default Press;
