import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavLinkProps extends LinkProps {
  children: React.ReactNode;
  color?: string;
  to?: string;
}

const NavLink = ({ children, color = 'leaseEndBlue', to, isExternal, ...props }: NavLinkProps) => {
  const location = useLocation();
  const { pathname } = location;

  const active = pathname === to;

  return (
    <ChakraLink
      fontWeight={active ? '800' : '600'}
      to={isExternal ? undefined : to}
      href={isExternal ? to : undefined}
      color={active ? 'oceanBoatBlue' : color}
      fontSize="16px"
      isExternal={isExternal}
      {...props}
    >
      {isExternal ? (
        <>{children}</>
      ) : (
        <>
          <Link
            to={{
              pathname: isExternal ? undefined : to,
            }}
          >
            {children}
          </Link>
        </>
      )}
    </ChakraLink>
  );
};

export default NavLink;
