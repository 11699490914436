import { Image, ImageProps, Link, LinkProps } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface Props extends LinkProps {
  src: NonNullable<ImageProps['src']>;
  to?: string;
  link?: string;
  size?: NonNullable<LinkProps['size']>;
  alt?: string;
  ariaLabel?: string;
  imageProps?: ImageProps;
}

const LogoLink = ({ src, to, link, size, alt, ariaLabel, imageProps, ...rest }: Props) => {
  return (
    <Link
      as={to ? ReactRouterLink : undefined}
      to={to}
      href={link}
      aria-label={ariaLabel}
      isExternal={!!link}
      {...rest}
    >
      <Image src={src} h={size} alt={alt} {...imageProps} />
    </Link>
  );
};

export default LogoLink;
