import { Box, Container, Link, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Title from '../../components/Title';
import { leAssetFetch, leAssetFolderFetch } from '../Sanity/Data';
import { LEAssetFolderProps, LEAssetProps } from '../Sanity/Model';
import Asset from './Asset';
import Folder from './Folder';

const Assets = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loading, setLoading] = useState<boolean>(false);
  const [folders, setFolders] = useState<LEAssetFolderProps[] | null>(null);
  const [assets, setAssets] = useState<LEAssetProps[] | null>(null);

  const fetch = async () => {
    setLoading(true);
    if (!params.get('folder')) {
      setAssets(null);
      const data: LEAssetFolderProps[] | null = await leAssetFolderFetch();
      setLoading(false);
      if (data) {
        setFolders(data);
      }
      return;
    }
    if (params.get('folder')) {
      setFolders(null);
      const data: LEAssetProps[] | null = await leAssetFetch(params.get('folder') || '');
      setLoading(false);
      if (data) {
        setAssets(data);
      }
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Container my={8}>
      <Title fontSize={{ base: '24px', md: '32px' }}>
        {params.get('folder') ? <>{params.get('folder')}</> : 'Lease End Assets'}
      </Title>
      {params.get('folder') && <Link href={location.pathname}>Back</Link>}
      <Box pt={2} pb={4}>
        {loading ? (
          <>
            <Text>Loading</Text>
          </>
        ) : folders && folders.length ? (
          folders.map((folder: LEAssetFolderProps) => {
            return <Folder folder={folder} />;
          })
        ) : assets && assets.length ? (
          assets.map((asset: LEAssetProps) => {
            return <Asset asset={asset} />;
          })
        ) : (
          <Text>Nothing found</Text>
        )}
      </Box>
    </Container>
  );
};

export default Assets;
