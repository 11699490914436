import { Box, Center, Divider, Flex, Hide, Img, Show, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import {
  LeaseEndCarHauler,
  LeaseEndGuyGivingMoney,
  LeaseEndGuyHuggingCar,
} from '../../assets/Images';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import GetStarted from '../../components/ui/GetStarted/GetStarted';
import { LDFlags } from '../../constants/experiments';
import useFlag from '../../hooks/useFlag';
import FourSteps from './components/FourSteps';
import KeepOrSellButton from './components/KeepOrSellButton';

const HowItWorks = () => {
  const [isKeepMyCar, setIsKeepMyCar] = React.useState<boolean>(true);
  const acquisitionsFlow = useFlag(LDFlags.ACQUISITIONS_FLOW);

  const boxTextInformative = useMemo(() => {
    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        borderColor="white"
        boxShadow="2px 2px 3px 0.5px #E0E0E0"
        marginBottom="auto"
        backgroundColor="white"
        maxW={{ lg: acquisitionsFlow ? '35vw' : '52vw', base: '90vw' }}
      >
        <Text fontWeight="medium" padding={5} fontSize={{ base: 16, lg: 24 }} color="leaseEndBlue">
          {isKeepMyCar
            ? 'If you can’t stand to let your leased car go – or just want a good deal on a used car you’re already familiar with – you can buy out your lease and keep your car. We’ll find a monthly payment that fits your budget and takes any coverage costs into account.'
            : 'Time to part ways with your leased car? Selling your car to us is easy. Your sale can be completed entirely online, no in-person inspection is required, and we’ll even come to you to pick up the car.'}
        </Text>
      </Box>
    );
  }, [isKeepMyCar]);

  return (
    <Box>
      <TitleContainer>
        <Title fontSize={{ base: '40px', md: '52px' }}>Here’s how it works.</Title>
      </TitleContainer>
      {acquisitionsFlow && (
        <>
          <Box textAlign="center" marginBottom={{ base: '40px', lg: '70px' }}>
            <Text fontSize={{ base: 14, lg: 20 }}>
              We offer 2 lease-end solutions – buying out and keeping your car, or selling it.
            </Text>
            <Subtitle display={{ base: 'block', lg: 'none' }} fontSize={12} textColor="#007DC5">
              Tap to explore your options
            </Subtitle>
          </Box>
          <KeepOrSellButton isKeepMyCar={isKeepMyCar} onSelected={setIsKeepMyCar} />
          <Show above="lg">
            <Box position="absolute" left={0} width="100%" marginTop="-20px">
              <Divider borderColor="grayBackground" />
            </Box>
          </Show>
        </>
      )}
      <Flex
        flexDirection={acquisitionsFlow ? 'row' : 'column'}
        alignItems={acquisitionsFlow ? 'start' : 'center'}
      >
        <Img
          display={{ base: 'none', lg: 'block' }}
          marginBottom="15px"
          marginLeft={acquisitionsFlow ? { md: '10%', lg: '10%', xl: '15%', '2xl': '20%' } : 0}
          src={isKeepMyCar ? LeaseEndGuyHuggingCar : undefined}
        />
        <Hide above="lg">
          <Box
            backgroundColor={isKeepMyCar ? acquisitionsFlow && 'maize' : 'carrotOrange'}
            width="100vw"
            display="flex"
            justifyContent="center"
            marginTop={-7}
            padding={5}
            transition="all 0.5s ease"
          >
            {boxTextInformative}
          </Box>
        </Hide>
        <Show above="lg">{boxTextInformative}</Show>
        <Img
          display={{ base: 'none', lg: !isKeepMyCar ? 'block' : 'none' }}
          w={700}
          marginTop="110px"
          marginBottom="35px"
          src={LeaseEndCarHauler}
        />
        <Img
          display={{ base: 'none', lg: !isKeepMyCar ? 'block' : 'none' }}
          marginLeft="-720px"
          src={LeaseEndGuyGivingMoney}
        />
      </Flex>
      <Flex
        backgroundColor="grayBackground"
        width={{ md: '100vw' }}
        justifyContent="center"
        marginTop={acquisitionsFlow ? '-100px' : { xl: '-240px', lg: '-320px' }}
        padding={5}
        flexDir="column"
        alignItems="center"
      >
        <FourSteps isKeepMyCar={isKeepMyCar} />
      </Flex>
      <Center pt={{ base: 8, md: 0 }} pb={{ base: 8, md: 12 }} bgColor={{ md: 'grayBackground' }}>
        <GetStarted />
      </Center>
    </Box>
  );
};

export default HowItWorks;
