import { Text, TextProps } from '@chakra-ui/react';

interface EndorsementParagraphProps extends TextProps {
  paragraph: string;
}
const EndorsementParagraph = ({ paragraph, ...rest }: EndorsementParagraphProps) => {
  return (
    <>
      <Text fontSize="18px" lineHeight="26px" fontWeight={400} textAlign="left" {...rest}>
        {paragraph}
      </Text>
    </>
  );
};

export default EndorsementParagraph;
