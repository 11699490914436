/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { BoxProps, Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik, FormikContextType } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { statesArray } from '../constants/states';
import { FLOW_ENTRY } from '../constants/urls';
import { INVALID_VIN_MSG } from '../constants/vin';
import { TemporaryShoppingCartDataInput } from '../gql/generated/graphql';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import { NonNullRequired } from '../utils/types/typeHelpers';
import validateLicenseNumber, {
  INVALID_LICENSE_PLATE_MSG,
} from '../utils/validation/licenseNumberValidator';
import validateVin from '../utils/validation/vinValidator';
import SwitchEndorsement from './SwitchEndorsement';
import Input from './formComponents/Input';
import Select from './formComponents/Select';

const YEAR_DIFF = 8;

const licensePlateValidationSchema = Yup.object({
  license_plate_number: Yup.string()
    .required('Required field')
    .test('is-valid-license-number', INVALID_LICENSE_PLATE_MSG, async (value) =>
      validateLicenseNumber({ licenseNumber: value }),
    ),
  state: Yup.string().required('Required field'),
});

const vinValidationSchema = Yup.object({
  vin: Yup.string()
    .required('Required field')
    .test('is-valid-vin', INVALID_VIN_MSG, (value) => validateVin(value)),
});

const LICENSE_PLATE_NAME = 'PLATE';
const VIN_NAME = 'VIN';

type FormFields = Yup.InferType<typeof licensePlateValidationSchema & typeof vinValidationSchema>;
type FormikContext = FormikContextType<FormFields>;
type setErrors = FormikContext['setErrors'];
type setValues = FormikContext['setValues'];

export const SHOPPING_CART_PARAMS = {
  PAYOFF: 'payoff',
  CREDIT_SCORE: 'credit_score',
  DOWN_PAYMENT: 'down_payment',
  TERM: 'term',
};

// will need to be synced with com
export const FLOW_ENTRY_PARAMS = {
  ZIP: 'zip',
  VIN: 'vin',
  LICENSE_PLATE: 'license_plate',
  STATE: 'state',
};

const createQueryParams = (
  values: FormFields,
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>,
  zip?: string,
) => {
  const { vin, license_plate_number, state } = values;
  const { buyout, credit_score, down_payment, loan_term } = shoppingCart || {};

  const params = {
    [FLOW_ENTRY_PARAMS.LICENSE_PLATE]: license_plate_number,
    [FLOW_ENTRY_PARAMS.STATE]: state,
    [FLOW_ENTRY_PARAMS.VIN]: vin,
    [FLOW_ENTRY_PARAMS.ZIP]: zip,
    [SHOPPING_CART_PARAMS.PAYOFF]: buyout,
    [SHOPPING_CART_PARAMS.CREDIT_SCORE]: credit_score,
    [SHOPPING_CART_PARAMS.DOWN_PAYMENT]: down_payment,
    [SHOPPING_CART_PARAMS.TERM]: loan_term,
  };

  const cleanedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    return { ...acc, [key]: value.toString() };
  }, {});

  return new URLSearchParams(cleanedParams).toString();
};

interface LicensePlateOrVinInputProps extends BoxProps {
  autoFocus?: boolean;
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>;
  zip?: string;
  btnLabel?: string;
}

const LicensePlateOrVinInputEndorsement = ({
  autoFocus = false,
  shoppingCart,
  zip,
  btnLabel = 'FIND MY LOAN',
}: LicensePlateOrVinInputProps) => {
  const [savedQueryParams] = useCookie<string>(CookieKeys.QUERY_PARAMS);
  const { search, pathname } = useLocation();
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const [dealExists, setDealExists] = useState(false);
  const [showOldCarWarning, setShowOldCarWarning] = useState(false);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;

  const handleWarningsReset = () => {
    setDealExists(false);
    setShowOldCarWarning(false);
  };

  const handleOnSwitchChange = (
    setErrors: setErrors,
    setValues: setValues,
    newValue: string,
  ): void => {
    setCurrentInput(newValue);
    setValues({ vin: '', license_plate_number: '', state: '' });
    setErrors({});
  };

  const handleSubmit = async (values: FormFields) => {
    const urlParams = new URLSearchParams(search || savedQueryParams || '');
    const queryParams = createQueryParams(values, shoppingCart, zip);
    let appendParams = '';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        appendParams += `&${key}=${value}`;
      });
    }
    rudderanalytics.track(RudderEvent.FlowEntry, {
      page: pathname,
      method: isLicensePlate ? 'License Plate' : 'VIN',
    });
    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.open(`${FLOW_ENTRY}?${queryParams + appendParams}`, '_self');
    }, 1);
  };

  return (
    <Formik<FormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        license_plate_number: '',
        state: '',
        vin: '',
      }}
      validationSchema={isLicensePlate ? licensePlateValidationSchema : vinValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setErrors, setValues }) => (
        <Form style={{ width: '100%' }}>
          <Flex bg="transparent" mx="auto" flexDirection="column" w="100%">
            <SwitchEndorsement
              firstOption={LICENSE_PLATE_NAME}
              secondOption={VIN_NAME}
              onChange={(val) => {
                handleOnSwitchChange(setErrors, setValues, val);
                handleWarningsReset();
              }}
            />
            <Flex mt="0" mb="2" minH="50px" gap={2}>
              {isLicensePlate ? (
                <>
                  <Input
                    name="license_plate_number"
                    placeholder="License Plate"
                    pr="5px"
                    autoFocus={autoFocus}
                    textTransform="uppercase"
                    onChange={handleWarningsReset}
                    invalid={dealExists || showOldCarWarning}
                    _container={{
                      borderWidth: 0,
                    }}
                    _input={{
                      borderWidth: 1,
                      borderColor: '#ddd',
                      borderRadius: 3,
                      h: '60px',
                      fontSize: 20,
                    }}
                  />
                  <Flex w={{ sm: '160px', md: '250px' }}>
                    <Select
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                      _control={{
                        ps: 8,
                        borderWidth: 1,
                        borderColor: '#ddd',
                        h: '60px',
                        fontSize: 20,
                      }}
                    />
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists || showOldCarWarning}
                  _container={{
                    borderWidth: 0,
                  }}
                  _input={{
                    borderWidth: 1,
                    borderColor: '#ddd',
                    borderRadius: 3,
                    h: '60px',
                    fontSize: 20,
                  }}
                />
              )}
            </Flex>
            {showOldCarWarning && (
              <Text
                as="span"
                color="red.500"
                fontWeight="semibold"
                textAlign="center"
                fontSize="12px"
              >
                We are typically unable to secure financing for leases that are {YEAR_DIFF}+ years
                old. Thank you.
              </Text>
            )}
            {!dealExists && !showOldCarWarning && (
              <Flex direction="column" alignItems="center" mt={2}>
                <Button
                  type="submit"
                  w="100%"
                  h="42px"
                  fontWeight="600"
                  isLoading={isSubmitting}
                  fontSize={18}
                >
                  {btnLabel}
                </Button>
              </Flex>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LicensePlateOrVinInputEndorsement;
