import { Box } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';

import { ptBaseComponents } from '../Sanity/Model';

const Answer = (props: { answer: [] }) => {
  const { answer } = props;
  return (
    <>
      <Box pb={8} fontSize={16} fontWeight={400}>
        <PortableText value={answer} components={ptBaseComponents} />
      </Box>
    </>
  );
};
export default Answer;
