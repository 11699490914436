import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';

import { GoogleReviews, Inc5000, TrustPilotIcon } from '../../../assets/Images';
import LogoLink from '../../../components/LogoLink';
import { BBBStars } from '../../../components/ui/reviews/ReviewStars';
import { GOOGLE, INC_5000, TRUSTPILOT } from '../../../constants/urls';

const ReviewLinks = ({ ...props }: BoxProps) => {
  return (
    <Box my={20} maxW="1400px" mx="auto" px={{ base: '20px', md: '50px' }} {...props}>
      <Flex gap={8}>
        <LogoLink
          src={TrustPilotIcon}
          link={TRUSTPILOT}
          size="65px"
          alt="Lease End's reviews from Trustpilot."
          ariaLabel="Lease End's reviews from Trustpilot."
          m="0 auto"
        />
        <LogoLink
          src={GoogleReviews}
          link={GOOGLE}
          size="65px"
          alt="Lease End's reviews from Google."
          ariaLabel="Lease End's reviews from Google."
          m="0 auto"
        />
        <Box m="0 auto">
          <BBBStars />
        </Box>
        <Flex flexDir="column" display={{ base: 'none', lg: 'block' }} m="0 auto">
          <LogoLink
            src={Inc5000}
            link={INC_5000}
            size="65px"
            alt="Lease End is the 171st fastest-growing company in the U.S."
            ariaLabel="Lease End is the 171st fastest-growing company in the U.S."
          />
          <Text fontSize="11px" w="180px" textAlign="center">
            Lease End is the 171st fastest-growing company in the U.S.
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent="center" flexDir="column" display={{ base: 'flex', lg: 'none' }} mt={10}>
        <LogoLink
          m="auto"
          src={Inc5000}
          link={INC_5000}
          size="65px"
          alt="Lease End is the 171st fastest-growing company in the U.S."
          ariaLabel="Lease End is the 171st fastest-growing company in the U.S."
        />
        <Box marginTop={3} textAlign="center">
          Lease End is the 171st fastest-growing company in the U.S.
        </Box>
      </Flex>
    </Box>
  );
};

export default ReviewLinks;
