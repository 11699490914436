import { Button, ButtonGroup } from '@chakra-ui/react';
import React, { useState } from 'react';

type Props = {
  onChange: (val: string) => unknown;
  firstOption: string;
  secondOption: string;
};

const SwitchSleek = ({ onChange, firstOption, secondOption }: Props) => {
  const [selected, setSelected] = useState(firstOption);

  const handleChange = (val: string): void => {
    setSelected(val);
    onChange(val);
  };

  return (
    <ButtonGroup borderRadius="24px" bg="transparent" w="100%" mb={8}>
      <Button
        bg="transparent"
        color="white"
        _hover={{
          borderColor: selected === firstOption ? 'royalBlue' : 'white',
        }}
        h="auto"
        w="50%"
        borderRadius="4px 4px 0 0"
        borderBottomWidth="2px"
        borderBottomColor={selected === firstOption ? 'royalBlue' : '#aaa'}
        background={selected === firstOption ? 'rgba(255,255,255,0.35)' : 'transparent'}
        fontSize={20}
        fontWeight={selected === firstOption ? 700 : 500}
        onClick={() => handleChange(firstOption)}
      >
        {firstOption}
      </Button>
      <Button
        bg="transparent"
        color="white"
        _hover={{
          borderColor: selected === secondOption ? 'royalBlue' : 'white',
        }}
        background={selected === secondOption ? 'rgba(255,255,255,0.35)' : 'transparent'}
        h="auto"
        w="50%"
        borderRadius="4px 4px 0 0"
        borderBottomWidth="2px"
        fontSize={20}
        fontWeight={selected === secondOption ? 700 : 500}
        borderBottomColor={selected === secondOption ? 'royalBlue' : '#aaa'}
        ml="0 !important"
        onClick={() => handleChange(secondOption)}
      >
        {secondOption}
      </Button>
    </ButtonGroup>
  );
};

export default SwitchSleek;
