/* eslint-disable no-underscore-dangle */

/* eslint-disable no-unused-vars */
import { Box, Container, Flex, Link } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import '../../assets/sanity.css';
import LicensePlateOrVinInput from '../../components/LicensePlateOrVinInput';
// import ModalWiki from '../../components/Modal/ModalWiki';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { LDFlags } from '../../constants/experiments';
import { BLOG, BLOG_SANITY } from '../../constants/urls';
import { useFlag } from '../../hooks';
import { objectEmpty } from '../../utils/objectEmpty';
import Loading from '../Loading';
import { postFetch } from '../Sanity/Data';
import { BlogPostProps, ptBlogComponents } from '../Sanity/Model';
import Author from './Author';
import TagLink from './TagLink';

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [post, setPost] = useState<BlogPostProps | null>(null);
  const SANITY_BLOG = useFlag(LDFlags.SANITY_BLOG);

  const fetch = async () => {
    setLoading(true);
    if (slug === '4-things-before-buyouts') {
      window.location.href = '/learn/buying-out-a-leased-car';
      return;
    }
    const data: BlogPostProps | null = await postFetch(slug);
    if (data && objectEmpty(data)) {
      window.location.href = '/learn';
      return;
    }
    setLoading(false);
    setPost(data);
  };

  const seo = () => {
    if (!document.querySelector('meta[name="description"]')) {
      const desc = document.createElement('meta');
      desc.name = 'description';
      desc.httpEquiv = 'X-UA-Compatible';
      desc.content = post?.description || '';
      document.getElementsByTagName('head')[0].appendChild(desc);
    } else {
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', post?.description || '');
    }
    if (!document.querySelector('meta[name="keywords"]')) {
      const desc = document.createElement('meta');
      desc.name = 'keywords';
      desc.httpEquiv = 'X-UA-Compatible';
      desc.content = post?.keywords || '';
      document.getElementsByTagName('head')[0].appendChild(desc);
    } else {
      document
        .querySelector('meta[name="keywords"]')
        ?.setAttribute('content', post?.keywords || '');
    }
    document.title = post?.browserTitle || document.title;
  };

  useEffect(() => {
    const handleUrlChange = () => {
      const id = decodeURI(window.location.hash.substring(1));
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };
    window.addEventListener('hashchange', handleUrlChange);
    return () => {
      window.removeEventListener('hashchange', handleUrlChange);
    };
  }, []);

  useEffect(() => {
    if (post) {
      seo();
    }
  }, [post]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Container color="leaseEndBlue" maxW="container.md" textAlign="center" p={0} mt={8}>
        {loading ? (
          <Loading />
        ) : post ? (
          <>
            <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              mx="auto"
            >
              {/* Post title */}
              <Link href={SANITY_BLOG ? BLOG_SANITY : BLOG}>Back to Learn</Link>
              <TitleContainer alignContent="center" justifyContent="flex-start" flexDirection="row">
                <Title
                  textAlign="left"
                  fontSize={{ base: '28px', md: '42px' }}
                  id="leTop"
                  fontWeight={700}
                >
                  {post.title || 'no title'}
                </Title>
              </TitleContainer>
              {/* Publish date */}
              {/* Post categories / tags */}
              <Author post={post} showDate />
              <Flex
                gap={1}
                pb={3}
                mb={3}
                borderBottomWidth={1}
                borderBottomColor="#ddd"
                flex={1}
                w="100%"
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                {post.categories.map((category: string) => {
                  return <TagLink tag={category} />;
                })}
              </Flex>
              {/* Post body */}
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                alignContent="flex-start"
                textAlign="left"
                className="le-blog-post"
                flex={1}
                w="100%"
              >
                <PortableText value={post.body} components={ptBlogComponents} />
              </Flex>
              {post.widget && (
                <Flex
                  mx="auto"
                  my={10}
                  w="auto"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#eee"
                  p={4}
                  borderRadius={8}
                >
                  <LicensePlateOrVinInput justifyContent="center" />
                </Flex>
              )}
            </Flex>
          </>
        ) : (
          <Box>Sorry, we couldn't load this post</Box>
        )}
      </Container>
      {/* <ModalWiki /> */}
    </>
  );
};

export default BlogPost;
