import { Box, Flex, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ImageComponent from '../Sanity/ImageComponent';
import { SalesProps } from '../Sanity/Model';

interface SalesTeamMemberProps {
  member: SalesProps;
}

const SalesTeamMember = ({ member }: SalesTeamMemberProps) => {
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <>
      {isMobile && (
        <Box
          padding={0}
          backgroundColor="royalBlue"
          borderRadius="50%"
          w={280}
          h={280}
          justifyContent="center"
          mx="auto"
        >
          {member.image && (
            <ImageComponent
              value={member.image}
              isInline={false}
              sync
              width={240}
              forceWidth="100%"
            />
          )}
        </Box>
      )}
      <Flex
        p={{ base: '10px', md: '15px 20px' }}
        maxWidth="1920px"
        m="0 auto"
        position="relative"
        zIndex="1"
        flexDirection="row"
        width="100%"
        gap={8}
        alignItems="flex-start"
        flex={0}
      >
        {!isMobile && member.image && (
          <ImageComponent value={member.image} isInline sync width={320} />
        )}
        <Flex flexDir="column">
          <Link onClick={() => history.push('/about-us/our-team')}>Back</Link>
          <Text fontSize={48} color="leaseEndBlue" fontWeight={300}>
            {member.name}
          </Text>
          <Text fontSize={24} color="black" fontWeight={700} my={1} mb={5}>
            {member.title}
          </Text>
          <Flex flexDir="column" fontSize={16} mb={1} fontWeight={500} color="leaseEndBlue">
            <Flex flexDir="row" mb={1}>
              <Text pe={1}>Location:</Text>
              <Text>{member.location}</Text>
            </Flex>
            <Flex flexDir="row" mb={1}>
              <Text pe={1}>Hometown:</Text>
              <Text>{member.homeTown}</Text>
            </Flex>
            <Flex flexDir="row" mb={1}>
              <Text pe={1}>Hobbies:</Text>
              <Text>{member.hobbies}</Text>
            </Flex>
          </Flex>
          <Text fontSize={24} color="black" fontWeight={700} my={5}>
            What do you love most about Lease End?
          </Text>
          <Text fontSize={18} color="black" fontWeight={400}>
            "{member.leaseEndLove}"
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default SalesTeamMember;
