import { Heading, HeadingProps } from '@chakra-ui/react';

interface EndorsementHeadingProps extends HeadingProps {
  heading: unknown;
}
const EndorsementHeading = ({ heading, ...rest }: EndorsementHeadingProps) => {
  return (
    <>
      <Heading
        fontSize="68px"
        lineHeight="78px"
        fontWeight={800}
        textTransform="uppercase"
        {...rest}
      >
        <>{heading}</>
      </Heading>
    </>
  );
};

export default EndorsementHeading;
