import { Flex, FlexProps, Image } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Logo, LogoWhite } from '../../assets/Images';
import { HOME } from '../../constants/urls';
import LPLogos from './LPLogos';

interface LPNavProps extends FlexProps {
  centered?: boolean;
}

const LPNav = ({ centered, ...props }: LPNavProps) => {
  return (
    <>
      {centered ? (
        <>
          <Flex
            alignItems="center"
            justifyContent="center"
            p={{ base: '10px', md: '15px 20px' }}
            maxWidth="1920px"
            m="0 auto"
            position="relative"
            zIndex="1"
            background="transparent"
            h={70}
          >
            <ReactRouterLink to={HOME}>
              <Image src={Logo} alt="Lease End Logo" w="130px" />
            </ReactRouterLink>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            maxW="1200px"
            w={{
              base: '80%',
              md: '100%',
            }}
            minW="300px"
            h={{
              base: '80px',
              md: '60px',
            }}
            px={{
              base: 0,
              md: 8,
            }}
            mx="auto"
            justifyContent={{
              //   base: 'center',
              base: 'space-between',
            }}
            alignItems="center"
            {...props}
          >
            {/* <Nav inverse /> */}
            <ReactRouterLink to={HOME}>
              <Image
                src={LogoWhite}
                alt="Lease End Logo"
                h={{
                  base: '40px',
                  md: '50px',
                }}
              />
            </ReactRouterLink>
            <Flex
              justifyContent="flex-end"
              gap={8}
              alignItems="center"
              display={{
                base: 'flex',
                md: 'flex',
              }}
            >
              <LPLogos />
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default LPNav;
