import { Flex, Image, Text } from '@chakra-ui/react';

import { EndorsementsLogoSimple } from '../../assets/Images';

const EndorsementsNav = () => {
  return (
    <>
      <Flex justifyContent="center" gap={4} my={4} flexDir="column" alignItems="center">
        <Image src={EndorsementsLogoSimple} w={280} />
        <Text
          color="#002d5d"
          fontWeight={700}
          textTransform="uppercase"
          fontSize={20}
          textAlign={{
            base: 'center',
          }}
        >
          hear directly from our customers about their experience working with us.
        </Text>
      </Flex>
    </>
  );
};

export default EndorsementsNav;
