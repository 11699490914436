import { Box, Center, Flex, Image, Spacer, Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { Logo } from '../../../assets/Images';
import { useGetVisibleArticlesQuery } from '../../../gql/generated/graphql';
import useDevice, { DevicesEnum } from '../../../hooks/useDevice';
import usePagination from '../../../hooks/usePagination';
import { ArticleTitle, StyledSubtitle, TwoLinesSubtitle } from '../../../pages/Press';
import Title from '../../Title';

const Articles = () => {
  const isMobile = useDevice() === DevicesEnum.mobile;
  const {
    currentPage,
    itemsPerPage,
    totalRecords,
    maxPage,
    nextPage,
    prevPage,
    jumpToPage,
    setTotalRecords,
  } = usePagination({ initialItmesPerPage: 4 });
  const { data: articles, loading } = useGetVisibleArticlesQuery({
    variables: { page: currentPage, pageSize: itemsPerPage },
  });

  useEffect(() => {
    if (articles?.visibleArticles?.total) {
      setTotalRecords(articles.visibleArticles.total);
    }
  }, [articles?.visibleArticles?.total]);

  return (
    <>
      <Flex py="15px">
        <Spacer />
        <Box w={{ base: '100%', lg: '80%' }}>
          <Title as="h2">Featured Stories</Title>
          {!loading && !totalRecords && (
            <StyledSubtitle>Check back later for more stories!</StyledSubtitle>
          )}
        </Box>
      </Flex>
      {loading && (
        <Center py="15px">
          <Spinner size="lg" />
        </Center>
      )}
      {!loading && totalRecords > 0 && (
        <Box py="15px">
          {articles?.visibleArticles?.results?.map((article) => (
            <Flex
              cursor="pointer"
              pb={{ base: '2rem', lg: '4.5rem' }}
              key={article?.id}
              onClick={() => {
                if (article?.url) {
                  // eslint-disable-next-line security/detect-non-literal-fs-filename
                  window.open(article.url, '_blank');
                }
              }}
              gap={{ base: '30px', lg: 'initial' }}
              justifyContent="space-between"
            >
              <Box w="15%">
                <Image src={article?.thumbnail || Logo} alt="Article Logo" />
              </Box>
              <Box w="80%">
                <ArticleTitle margin={0} bold size={!isMobile ? 'large' : 'small'}>
                  {article?.title}
                </ArticleTitle>
                {!isMobile && (
                  <TwoLinesSubtitle margin={0} size="small">
                    {article?.headline}
                  </TwoLinesSubtitle>
                )}
              </Box>
            </Flex>
          ))}
          <Center>
            <Flex gap="10px" fontSize="16px" color="royalBlue" fontWeight="600" textAlign="center">
              <Box cursor="pointer" w="25px" h="25px" onClick={prevPage}>
                {'<'}
              </Box>
              {new Array(maxPage).fill(1).map((key, index) => (
                <Box
                  cursor="pointer"
                  w="25px"
                  h="25px"
                  key={key}
                  onClick={() => jumpToPage(index)}
                  {...(index === currentPage && { bgColor: 'bostonBlue', color: 'white' })}
                >
                  {index + 1}
                </Box>
              ))}
              <Box cursor="pointer" w="25px" h="25px" onClick={nextPage}>
                {'>'}
              </Box>
            </Flex>
          </Center>
        </Box>
      )}
    </>
  );
};

export default Articles;
