/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable security/detect-unsafe-regex */

/* eslint-disable @typescript-eslint/ban-types */
import {
  Box,
  Button,
  ButtonProps,
  Container,
  Flex,
  Image,
  InputProps,
  Link,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { FaPhone } from 'react-icons/fa6';
import * as Yup from 'yup';

import {
  AllyBankLogo,
  CapitalOneBankLogo,
  ChaseBankLogo,
  FirstInterstateBankLogo,
  GoogleReviews,
  Logo,
  MacuBankLogo,
  PrequalCar,
  PrequalOrnament,
  PrequalSearch,
  TdBankLogoCrop,
  TrustPilotIcon,
} from '../assets/Images';
import Footer from '../components/Footer';
import LogoLink from '../components/LogoLink';
import Title from '../components/Title';
import Input from '../components/formComponents/Input';
import Select from '../components/formComponents/Select';
import { BBBStars } from '../components/ui/reviews/ReviewStars';
import { statesArray } from '../constants/states';
import { GOOGLE, HOME, TRUSTPILOT } from '../constants/urls';
import { useGetMarketPrequalificationMutation } from '../gql/generated/graphql';
import { CookieKeys, PrequalParams, useCookie } from '../hooks/useCookie';

type FormFields = Yup.InferType<typeof pqValidationSchema>;
const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const emailRegex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

const pqValidationSchema = Yup.object({
  pqFirstName: Yup.string().required('Required'),
  pqLastName: Yup.string().required('Required'),
  pqEmail: Yup.string().email().required('Enter a valid email'),
  pqPhone: Yup.string().required('Required').matches(phoneRegex, 'Invalid phone.'),
  pqAddress: Yup.string().required('Required'),
  pqCity: Yup.string().required('Required'),
  pqState: Yup.string().required('Required'),
  pqZip: Yup.string().required('Valid zip code'),
});

interface PrequalFormProps {
  setIsForm: Function;
}

const PQInput = ({ name = '', placeholder, type = 'text' }: InputProps) => {
  return (
    <>
      <Input
        _input={{
          py: 6,
          fontSize: 15,
        }}
        type={type}
        placeholder={placeholder}
        name={name}
      />
    </>
  );
};

const PrequalForm = ({ setIsForm }: PrequalFormProps) => {
  const [getMarketPrequal] = useGetMarketPrequalificationMutation();
  const [prequalCookie, setPrequalCookie] = useCookie<PrequalParams>(CookieKeys.PREQUAL_PARAMS);

  const handleSubmit = async (values: FormFields) => {
    const prequal = await getMarketPrequal({
      variables: {
        prequalId: prequalCookie?.prequalId,
        firstName: values.pqFirstName,
        lastName: values.pqLastName,
        phoneNumber: values.pqPhone,
        addressLine: values.pqAddress,
        city: values.pqCity,
        state: values.pqState,
        zip: values.pqZip,
      },
    });

    if (!prequal.data?.getMarketPrequalification) {
      // error
      return null;
    }

    setPrequalCookie({
      prequalId: prequal.data?.getMarketPrequalification.prequalId,
      firstName: values.pqFirstName,
      lastName: values.pqLastName,
      address: values.pqAddress,
      city: values.pqCity,
      state: values.pqState,
      zip: values.pqZip,
      email: values.pqEmail,
      phone: values.pqPhone || '',
    });
    console.log(prequal.data?.getMarketPrequalification.creditScoreTier);
    return null;
  };

  return (
    <>
      <Formik<FormFields>
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          pqFirstName: '',
          pqLastName: '',
          pqEmail: '',
          pqPhone: '',
          pqAddress: '',
          pqCity: '',
          pqState: '',
          pqZip: '',
        }}
        validationSchema={pqValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <Container p={5} pb={0} mt={8} pos="relative" zIndex={2}>
              <Title
                textAlign="center"
                color="#000"
                fontSize={{
                  base: isSubmitting ? 24 : 42,
                  md: isSubmitting ? 32 : 42,
                }}
                fontWeight={700}
                lineHeight={1.2}
              >
                {!isSubmitting ? (
                  <>
                    Get prequalified
                    <Text as="br" />
                    with{' '}
                    <Text as="span" textDecoration="underline">
                      no credit hit
                    </Text>
                    !
                  </>
                ) : (
                  <>Looking Up Your Credit File</>
                )}
              </Title>
              <Text
                textAlign="center"
                fontSize={16}
                fontWeight={400}
                color="#000"
                px={4}
                lineHeight={1.2}
              >
                {!isSubmitting ? (
                  <>
                    In less than{' '}
                    <Text as="span" fontWeight={800}>
                      1 minute
                    </Text>{' '}
                    we’ll get you prequalified to end your lease and keep your car... with{' '}
                    <Text as="span" textDecoration="underline" fontWeight={800}>
                      no credit hit
                    </Text>
                    !
                  </>
                ) : (
                  <>
                    <Text fontSize={16} fontWeight={500} color="#888">
                      Remember, this won't affect your credit score
                    </Text>
                  </>
                )}
              </Text>
              {isSubmitting && (
                <Flex justify="center" mt={2}>
                  <Image src={PrequalSearch} w={280} />
                </Flex>
              )}
              {!isSubmitting && (
                <>
                  <Flex flexDir="column" gap={2} m={2} mt={4} pt={4} borderTopWidth={1}>
                    <Flex gap={2} mb={2}>
                      <PQInput name="pqFirstName" placeholder="First name" />
                      <PQInput name="pqLastName" placeholder="Last name" />
                    </Flex>
                    <Flex gap={2} pb={2}>
                      <Flex w="60%">
                        <PQInput name="pqEmail" placeholder="Email" />
                      </Flex>
                      <Flex flex={1}>
                        <PQInput name="pqPhone" placeholder="Phone" />
                      </Flex>
                    </Flex>
                    <Flex gap={2}>
                      <PQInput name="pqAddress" placeholder="Home address" />
                    </Flex>
                    <Flex gap={2}>
                      <Flex w="48%">
                        <PQInput name="pqCity" placeholder="City" />
                      </Flex>
                      <Flex w="29%">
                        <Select
                          name="pqState"
                          placeholder="State"
                          options={statesArray}
                          invalid={false}
                          _control={{
                            ps: 8,
                            borderWidth: 1,
                            borderColor: '#bcbcbc',
                            h: '44px',
                            fontSize: 15,
                          }}
                        />
                      </Flex>
                      <Flex w="23%">
                        <PQInput name="pqZip" placeholder="Zip" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex flex={1} mx={2} borderTopWidth={1} pt={4} mt={4} flexDir="column">
                    <Button
                      isLoading={isSubmitting}
                      display="flex"
                      flex={1}
                      type="submit"
                      bg="#52B47F"
                      color="white"
                      letterSpacing={0}
                      fontSize={18}
                      fontWeight={800}
                      _hover={{
                        background: '#38a76b',
                      }}
                    >
                      Get My Prequalification
                    </Button>
                    <Text textAlign="center" color="#888" fontSize={11} mt={2}>
                      By clicking "Get My Prequalification," I authorize Lease End to acquire my
                      credit report, otherwise known as a soft inquiry which will not affect my
                      credit file. I understand that this will be used for pre-qualification
                      purposes only to determine if I prequalify.
                    </Text>
                  </Flex>
                </>
              )}
              {!isSubmitting && (
                <Flex
                  w={{
                    base: 'auto',
                  }}
                  flexDir={{
                    base: 'row',
                  }}
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  gap={4}
                  flex={1}
                  mt={8}
                  mb={{
                    base: 4,
                    md: 28,
                  }}
                >
                  <LogoLink
                    src={GoogleReviews}
                    link={GOOGLE}
                    size="65px"
                    alt="Lease End's reviews from Google."
                    ariaLabel="Lease End's reviews from Google."
                    m="0 auto"
                  />
                  <Box m="0 auto">
                    <BBBStars />
                  </Box>
                  <LogoLink
                    src={TrustPilotIcon}
                    link={TRUSTPILOT}
                    size="60px"
                    alt="Lease End's reviews from Trustpilot."
                    ariaLabel="Lease End's reviews from Trustpilot."
                  />
                </Flex>
              )}
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

const Subtitle = ({ ...rest }: TextProps) => {
  return (
    <>
      <Text {...rest}>
        In less than 1 minute we’ll get you prequalified to end your lease and keep your car... with{' '}
        <Text as="span" textDecoration="underline" fontWeight={700}>
          no credit hit
        </Text>
        .
      </Text>
    </>
  );
};

interface CTAProps extends ButtonProps {
  setIsForm: Function;
}
const CTA = ({ setIsForm, ...rest }: CTAProps) => {
  return (
    <>
      <Button
        bg="#52B47F"
        color="#fff"
        fontSize={{
          base: 20,
          md: 24,
        }}
        letterSpacing={0}
        fontWeight={700}
        borderRadius={5}
        flex={1}
        py={10}
        onClick={() => setIsForm(true)}
        _hover={{
          background: '#38a76b',
        }}
        {...rest}
      >
        Get Prequalified Now
      </Button>
    </>
  );
};

const Disclaimer = ({ ...rest }: TextProps) => {
  return (
    <>
      <Text {...rest}>
        This pre-qualification program uses a soft inquiry on your personal credit report to assess
        your credit worthiness. The result is not an approval or denial of credit, nor a guarantee
        for financing. A credit application using a hard credit pull will still be required .
      </Text>
    </>
  );
};

const Prequal = () => {
  const [isForm, setIsForm] = useState<boolean>(false);
  return (
    <>
      <Flex
        flex={1}
        height="100vh"
        background="linear-gradient(180deg, rgba(186,244,255,1) 0%, rgba(186,244,255,0.5) 5%, rgba(255,255,255,1) 50%, rgba(186,244,255,0.5) 95%, rgba(186,244,255,1) 100%)"
        flexDir="column"
      >
        <Flex bg="#002d5d" height="28px" alignItems="center">
          <Container color="leaseEndBlue" maxW={isForm ? '480px' : 'container.xl'} height="28px">
            <Flex
              alignItems="center"
              justifyContent={{ base: 'center', md: isForm ? 'center' : 'flex-end' }}
              gap={16}
              mt={1}
            >
              <Flex gap={1} alignItems="center">
                <FaPhone color="white" />
                <Text fontWeight={700} color="white">
                  (844) 902-2842
                </Text>
              </Flex>
              {!isForm && (
                <Link
                  display={{
                    base: 'none',
                    md: 'block',
                  }}
                  color="white"
                >
                  Contact Us
                </Link>
              )}
            </Flex>
          </Container>
        </Flex>
        <Container
          color="leaseEndBlue"
          maxW={isForm ? '480px' : 'container.xl'}
          textAlign="center"
          p={0}
          mt={8}
        >
          {/* Nav */}
          <Flex
            flex={1}
            justifyContent={{
              base: isForm ? 'flex-start' : 'center',
              md: isForm ? 'center' : 'space-between',
            }}
            mb={isForm ? 4 : 0}
            pos="relative"
          >
            <Image
              display={{
                base: isForm ? 'block' : 'none',
                md: 'none',
              }}
              src={PrequalOrnament}
              w={200}
              pos="absolute"
              right="-80px"
              top="-40px"
              zIndex={1}
            />
            <Link
              href={HOME}
              ms={{
                base: isForm ? 4 : 0,
                md: 0,
              }}
            >
              <Image src={Logo} w={130} />
            </Link>
            {!isForm && (
              <Button
                color="white"
                bg="#52B47F"
                py={6}
                letterSpacing={0}
                fontSize={16}
                onClick={() => setIsForm(true)}
                _hover={{
                  background: '#38a76b',
                }}
                display={{
                  base: 'none',
                  md: 'flex',
                }}
              >
                Get Prequalified
              </Button>
            )}
          </Flex>

          {isForm ? (
            <PrequalForm setIsForm={setIsForm} />
          ) : (
            <Flex flexDir="column" mt={8}>
              <Flex
                gap={{
                  base: 0,
                  md: 8,
                }}
                flexDir={{
                  base: 'column',
                  md: 'row',
                }}
              >
                <Flex flex={1} flexDir="column" gap={8}>
                  <Title
                    textAlign={{
                      base: 'center',
                      md: 'left',
                    }}
                    color="#000"
                    fontSize={{
                      base: 38,
                      md: 54,
                    }}
                    fontWeight={800}
                    lineHeight={1.1}
                  >
                    Get prequalified
                    <Text as="br" />
                    with{' '}
                    <Text as="span" textDecoration="underline">
                      no credit hit
                    </Text>
                    !
                  </Title>

                  <Subtitle
                    textAlign={{
                      base: 'center',
                      md: 'left',
                    }}
                    fontSize={16}
                    fontWeight={500}
                    color="#000"
                    pe={{
                      base: 8,
                      md: 24,
                    }}
                    ps={{
                      base: 8,
                      md: 0,
                    }}
                  />
                  <Flex
                    flexDir="row"
                    pt={4}
                    gap={1}
                    pe={24}
                    display={{
                      base: 'none',
                      md: 'flex',
                    }}
                  >
                    <CTA setIsForm={setIsForm} />
                  </Flex>
                  <Disclaimer
                    display={{
                      base: 'none',
                      md: 'block',
                    }}
                    textAlign="left"
                    color="#888"
                    fontSize={11}
                    pe={24}
                  />
                </Flex>
                <Box
                  w={{
                    base: '100%',
                    md: '50%',
                  }}
                  mt={{
                    base: '-12px',
                    md: '-24px',
                  }}
                  flexDir="column"
                  pos="relative"
                  ms={{
                    base: 12,
                    md: 0,
                  }}
                >
                  <Image src={PrequalOrnament} pos="relative" w="100%" />
                </Box>
                <CTA
                  display={{
                    base: 'block',
                    md: 'none',
                  }}
                  setIsForm={setIsForm}
                  mt="-24px"
                  mx={8}
                  py={8}
                />
              </Flex>
              <Flex
                mt={{
                  base: 0,
                  md: '-36px',
                }}
                borderTopWidth={{
                  base: 0,
                  md: 1,
                }}
                pt={8}
                flexDir={{
                  base: 'column',
                  md: 'row',
                }}
              >
                <Flex
                  w="25%"
                  pos="relative"
                  zIndex={1}
                  display={{
                    base: 'none',
                    md: 'flex',
                  }}
                >
                  <Image src={PrequalCar} w="100%" />
                </Flex>
                <Flex
                  pos="relative"
                  zIndex={2}
                  flex={1}
                  mx={{
                    base: 8,
                    md: 0,
                  }}
                  flexDir={{
                    base: 'column',
                    md: 'row-reverse',
                  }}
                  gap={{
                    base: 4,
                    md: 8,
                  }}
                  p={4}
                  borderRadius={12}
                  boxShadow={{
                    base: '-20px 25px 15px rgba(0,0,0,0.12)',
                    md: '-40px 0 20px rgba(0,0,0,0.12)',
                  }}
                  background="white"
                >
                  <Flex
                    flex={1}
                    me={{
                      base: 0,
                      md: 8,
                    }}
                    alignItems="center"
                  >
                    <Text
                      textAlign={{
                        base: 'center',
                        md: 'left',
                      }}
                      fontSize={{
                        base: 14,
                        md: 15,
                      }}
                      color="#000"
                      fontWeight={600}
                    >
                      Once prequalified we will secure a loan for you from one of our trusted
                      lending partners to end your lease and keep your car.
                    </Text>
                  </Flex>
                  <Flex
                    gap={{
                      base: 4,
                      md: 8,
                    }}
                    alignItems="center"
                    flexWrap="wrap"
                    px={3}
                    py={{
                      base: 3,
                      md: 6,
                    }}
                    w={{
                      base: '100%',
                      md: '60%',
                    }}
                    justifyContent="center"
                  >
                    <Image h="32px" src={AllyBankLogo} />
                    <Image h="24px" src={ChaseBankLogo} />
                    <Image h="32px" src={CapitalOneBankLogo} />
                    <Image h="32px" src={TdBankLogoCrop} />
                    <Image h="42px" src={MacuBankLogo} />
                    <Image h="32px" src={FirstInterstateBankLogo} />
                  </Flex>
                </Flex>
                <Flex
                  w={{
                    base: 'auto',
                    md: '20%',
                  }}
                  flexDir={{
                    base: 'row',
                    md: 'column',
                  }}
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  gap={4}
                  my={{
                    base: 16,
                    md: 0,
                  }}
                  mx={{
                    base: 16,
                    md: 0,
                  }}
                >
                  <LogoLink
                    src={GoogleReviews}
                    link={GOOGLE}
                    size="65px"
                    alt="Lease End's reviews from Google."
                    ariaLabel="Lease End's reviews from Google."
                    m="0 auto"
                  />
                  <Box m="0 auto">
                    <BBBStars />
                  </Box>
                  <LogoLink
                    src={TrustPilotIcon}
                    link={TRUSTPILOT}
                    size="60px"
                    alt="Lease End's reviews from Trustpilot."
                    ariaLabel="Lease End's reviews from Trustpilot."
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </Container>
        <Footer _prequal mt={isForm ? 0 : 8} borderTopWidth={isForm ? 0 : 1} />
      </Flex>
    </>
  );
};

export default Prequal;
