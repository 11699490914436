import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import useDevice, { DevicesEnum } from '../../hooks/useDevice';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  const device = useDevice();

  return (
    <Box minH="100vh">
      {device === DevicesEnum.mobile ? (
        <>
          <Nav />
          <Flex justifyContent="center" flexGrow={1}>
            <Container>{children}</Container>
          </Flex>
          <Footer />
        </>
      ) : (
        <>
          <Nav />
          <Container maxW="container.xl" minH="100vh">
            <Flex justifyContent="center" flexGrow={1}>
              {children}
            </Flex>
            <Footer />
          </Container>
        </>
      )}
    </Box>
  );
};

export default Main;
