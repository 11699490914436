import { Box } from '@chakra-ui/react';
import { useState } from 'react';

import { FAQProps } from '../Sanity/Model';
import Answer from './Answer';
import Question from './Question';

const Item = (props: { faq: FAQProps }) => {
  const { faq } = props;
  const [show, setShow] = useState<boolean>(false);
  const toggleShow = () => {
    setShow(!show);
  };
  return (
    <>
      <Box borderTop="1px solid #ddd">
        <Box onClick={() => toggleShow()}>
          <Question question={faq.question} isOpen={show} />
        </Box>
        <Box
          style={{
            display: show ? 'block' : 'none',
          }}
        >
          <Answer answer={faq.answer} />
        </Box>
      </Box>
    </>
  );
};

export default Item;
