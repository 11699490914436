import { Box, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { LEAssetFolderProps } from '../Sanity/Model';

const Folder = (props: { folder: LEAssetFolderProps }) => {
  const location = useLocation();
  const { folder } = props;
  const go = (name: string) => {
    window.location.href = `${location.pathname}?folder=${name}`;
  };
  return (
    <>
      <Box
        border="1px solid #ddd"
        mt={2}
        ps={8}
        pe={8}
        pb={2}
        borderRadius={8}
        cursor="pointer"
        _hover={{
          background: '#fafafa',
          borderColor: '#ccc',
        }}
      >
        <Heading
          pt={6}
          pb={4}
          mt={0}
          size="lg"
          fontWeight={700}
          lineHeight={1.3}
          fontSize="18pt"
          color="leaseEndBlueDark"
          onClick={() => go(folder.title)}
        >
          {folder.title}
        </Heading>
      </Box>
    </>
  );
};

export default Folder;
