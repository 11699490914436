import { Box, Flex, Image, Text } from '@chakra-ui/react';

import {
  Endorsements12Min,
  Endorsements17x,
  Endorsements76perc,
  Endorsements84perc,
  Endorsements86perc,
  EndorsementsLogo,
  SingleQuote,
} from '../../assets/Images';
import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import EndorsementHeading from './EndorsementHeading';
import EndorsementParagraph from './EndorsementParagraph';
import EndorsementVideo from './EndorsementVideo';

const EndorsementSkip = () => {
  return (
    <>
      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={12}
      >
        <Flex
          bg="#5093A7"
          w={{
            base: '100%',
            lg: '60%',
          }}
          flexDir="column"
          py={10}
          ps={10}
          pe={5}
          pos="relative"
          zIndex={1}
        >
          <Image src={EndorsementsLogo} w={220} pos="absolute" left={8} top="-24px" />
          <EndorsementHeading
            heading="they skipped the dealership"
            color="#F4C53E"
            textAlign="left"
            mt={16}
          />
          <EndorsementParagraph
            color="white"
            paragraph="These people all skipped the hassle of pushy car salespeople and lengthy paperwork by
              working with Lease End. You can buy out your lease quickly and easily from home while
              we handle all the details, from finding the best financing options to getting your new
              car's plates. It's a simple, stress-free way to purchase your leased vehicle without
              ever setting foot in a dealership."
          />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
        >
          <EndorsementVideo name="Montage-NoDealership" />
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column-reverse',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={8}
      >
        <Flex
          justifyContent="center"
          w={{
            base: '100%',
            lg: '60%',
          }}
          mb={{
            base: '16px',
            lg: '48px',
          }}
          mt={{
            base: 0,
            lg: '-48px',
          }}
          pos="relative"
          zIndex={2}
        >
          <Box
            w={{
              base: '100%',
              lg: '75%',
            }}
            bg="#FFF"
            p={3}
            borderRadius={8}
            boxShadow="0 0 10px rgba(0,0,0,0.18)"
            mt={{
              base: 8,
              lg: 0,
            }}
          >
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SKIP THE DEALERSHIP" />
          </Box>
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
          pos="relative"
        >
          <Image
            src={SingleQuote}
            pos="absolute"
            w="48px"
            top="-16px"
            zIndex={1}
            opacity="0.1"
            left="-16px"
          />
          <Text
            fontSize={16}
            fontStyle="italic"
            me={{
              base: 0,
              lg: 16,
            }}
            ms={{
              base: 4,
              lg: 0,
            }}
            mt={4}
            textAlign="left"
            pos="relative"
            zIndex={2}
          >
            From the first phone call to a signed contract took less than three hours. The process
            was a breeze. Thanks for saving me the pain of having to go to the dealership (with
            their hidden fees and high pressure sales tactics.) I’m relieved to have the lease
            buyout process dealt with. I talked to a few companies, and feel very happy I chose
            Lease End. Thanks!
          </Text>
        </Flex>
      </Flex>

      <Flex
        justifyContent="center"
        flex={1}
        w="100%"
        gap={8}
        borderBottomWidth={1}
        borderTopWidth={1}
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
      >
        <Flex
          w={{
            base: '100%',
            lg: 325,
          }}
          bg="#5093A7"
          alignItems="center"
          ps={8}
        >
          <EndorsementHeading
            color="#fff"
            heading="WE saved them time"
            textAlign={{
              base: 'center',
              lg: 'left',
            }}
            lineHeight="72px"
          />
        </Flex>
        <Flex
          flex={1}
          flexDir="column"
          alignItems="center"
          py={{
            base: 5,
            lg: 10,
          }}
        >
          <Text
            fontSize={24}
            color="#002D5D"
            textTransform="uppercase"
            textAlign="center"
            fontWeight={800}
            mb={4}
          >
            Lease End
            <Text as="br" />
            takes only 12 minutes
          </Text>
          <Image w="100" maxW={200} src={Endorsements12Min} />
        </Flex>
        <Flex
          flex={1}
          borderStartWidth={{
            base: 0,
            lg: 1,
          }}
          px={8}
          flexDir="column"
          alignItems="center"
          py={{
            base: 5,
            lg: 10,
          }}
        >
          <Text
            fontSize={24}
            color="#002D5D"
            textTransform="uppercase"
            textAlign="center"
            fontWeight={800}
            mb={4}
          >
            The Dealership
            <Text as="br" />
            can take 3.5+ hours!
          </Text>
          <Image w="100%" src={Endorsements17x} mb={4} />
          <Text fontSize="12px" color="#787878" textAlign="left">
            *According to J.D. Power’s U.S. Sales Satisfaction Index, the average time spent
            completing a deal at a dealership is 3.6 hours.
          </Text>
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={{
          base: 8,
          lg: 16,
        }}
        gap={8}
      >
        <Flex
          w={{
            base: '100%',
            lg: '60%',
          }}
        >
          <EndorsementParagraph
            pt={{
              base: 0,
              lg: 4,
            }}
            pb={{
              base: 4,
              lg: 0,
            }}
            pe={8}
            fontSize={22}
            lineHeight="32px"
            fontWeight={400}
            textAlign={{
              base: 'center',
              lg: 'left',
            }}
            paragraph="Spend less time in the dealership and more time with your car by buying out your lease
              with Lease End. If pushy dealerships are the only thing standing between you and your
              car's happily-ever-after, let Lease End be your matchmaker. We'll take care of the
              details while you start planning your road trips together!"
          />
        </Flex>
        <Flex
          bg="white"
          w={{
            base: '100%',
            lg: '40%',
          }}
          flexDir="column"
          mb={8}
        >
          <Box bg="#FFF" p={3} borderRadius={8} boxShadow="0 0 10px rgba(0,0,0,0.18)">
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SAVE ME SOME TIME" />
          </Box>
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        bg="#F4C53E"
        mt={8}
      >
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
        >
          <EndorsementVideo name="Glenn-NoDealer" />
          <EndorsementParagraph
            pt={8}
            px={16}
            fontWeight={400}
            fontStyle="italic"
            fontSize={18}
            paragraph="According to a KPA Dealership Trust Survey there’s no love lost for car dealers."
            display={{
              base: 'none',
              lg: 'block',
            }}
          />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
          p={8}
        >
          <EndorsementHeading heading="They avoided the runaround" textAlign="left" />
          <EndorsementParagraph
            fontWeight={600}
            fontSize={16}
            paragraph="Buying a car shouldn’t be complicated—especially when it’s one you’ve already been
              driving. At Lease End, we make it easy to find the best loan by cutting through the
              confusion and showing you which bank offers the best deal. No hidden fees, just a
              focus on getting you the best offer possible while providing the best customer
              service."
          />
          <Flex flexDir="column" flex={1} w="100%" gap={4} py={8} bg="#F4C53E">
            <Flex flex={1} gap={4}>
              <Image src={Endorsements86perc} w="80px" />
              <Flex flexDir="column" alignItems="flex-start" gap={1} justifyContent="center">
                <Text fontSize={20} textTransform="uppercase" fontWeight={800} textAlign="left">
                  hidden fees
                </Text>
                <Text fontSize={16} textAlign="left">
                  86% of people are concerned about hidden fees when leasing a vehicle.
                </Text>
              </Flex>
            </Flex>
            <Flex flex={1} gap={4}>
              <Image src={Endorsements84perc} w="80px" />
              <Flex flexDir="column" alignItems="flex-start" gap={1} justifyContent="center">
                <Text fontSize={20} textTransform="uppercase" fontWeight={800} textAlign="left">
                  NO TRANSPARENCY
                </Text>
                <Text fontSize={16} textAlign="left">
                  84% of people say price transparency is lacking at most car dealerships.
                </Text>
              </Flex>
            </Flex>
            <Flex flex={1} gap={4}>
              <Image src={Endorsements76perc} w="80px" />
              <Flex flexDir="column" alignItems="flex-start" gap={1} justifyContent="center">
                <Text fontSize={20} textTransform="uppercase" fontWeight={800} textAlign="left">
                  DISHONEST PRICING
                </Text>
                <Text fontSize={16} textAlign="left">
                  76% Of Americans Don’t Trust Car Dealers To Be Honest About Pricing
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default EndorsementSkip;
