import { BoxProps, Button, Flex, Link } from '@chakra-ui/react';

import { JobPostingProps } from '../Sanity/Model';

interface JobProps extends BoxProps {
  posting: JobPostingProps;
}

const Job = ({ posting }: JobProps) => {
  const go = (url: string) => {
    window.location.href = url;
  };
  return (
    <>
      <Flex flex={1} borderTopWidth={1} py={2} alignItems="center">
        <Flex w="80%">
          <Flex flexDir="column">
            <Link
              display="inline-flex"
              height="28px"
              lineHeight="28px"
              fontWeight={700}
              onClick={() => go(posting.url)}
            >
              {posting.name}
            </Link>
            <Flex justifyContent="flex-start">
              <Flex fontSize="12px">{posting.location}</Flex>
              <Flex fontSize="12px">{posting.department}</Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex w="20%">
          {posting.url && (
            <Button size="md" fontSize="12px" py={0} onClick={() => go(posting.url)}>
              APPLY
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Job;
