import { Box, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Footer from '../../components/Footer';
import LPNav from '../../components/Nav/LPNav';
import { COMErrorCodes } from '../../constants/errors';
import { QueryParams } from '../../constants/queryParam';
import { createErrorToast } from '../../utils/toast';
import ExpiredPayoffRequestModal from './components/ExpiredPayoffRequestModal';
import HowCanWeHelp from './components/HowCanWeHelp';
// import MidPageBanner from './components/MidPageBanner';
import ReviewLinks from './components/ReviewLinks';
import Steps from './components/Steps';
import UTMCarDudeBgImage from './components/UTMCarDudeBgImage';
import UTMHero from './components/UTMHero';

const KwlpLeaseBuyout = () => {
  const [expiredPayoffRequestModalOpen, setExpiredPayoffRequestModalOpen] = useState(false);
  const {
    replace,
    location: { search },
  } = useHistory();
  const toast = useToast();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const errCode = params.get(QueryParams.ERR_CODE);
    if (errCode === COMErrorCodes.NoDealFound) {
      params.delete(QueryParams.ERR_CODE);
      replace({ search: params.toString() });
      toast(
        createErrorToast({
          description:
            "We weren't able to find any information associated with the email you " +
            'provided. Please enter your License Plate or VIN to get started. If you think this ' +
            'is an error, please get in touch with our support team for help.',
        }),
      );
    }
  }, [search]);

  return (
    <>
      <Box h="100%">
        <LPNav centered />
        <UTMCarDudeBgImage />
        <UTMHero
          h1="Buyout your car lease early, hassle free."
          subtitle="We’ve arranged almost $1 billion in loans to help drivers buyout their car lease early"
        />
        <Steps />
        <ReviewLinks />
        <HowCanWeHelp />
        {/* <MidPageBanner /> */}
        <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
      </Box>
      <ExpiredPayoffRequestModal
        isOpen={expiredPayoffRequestModalOpen}
        onClose={() => setExpiredPayoffRequestModalOpen(false)}
      />
    </>
  );
};

export default KwlpLeaseBuyout;
