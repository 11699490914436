/* eslint-disable react/no-array-index-key */

/* eslint-disable prettier/prettier */
import { Container, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loading from '../Loading';
import { blogFetch } from '../Sanity/Data';
import { BlogPostProps } from '../Sanity/Model';
import Nav from './Nav';
import BlogPostSnippet from './Snippet';

const BlogTag = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<BlogPostProps[] | null>(null);
  const params: {
    tag: string;
  } = useParams();

  const fetch = async () => {
    setLoading(true);
    const data: BlogPostProps[] | null = await blogFetch(params && params.tag ? params.tag : null);
    setLoading(false);
    setPosts(data);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" p={0} mt={8}>
        <Nav />
        {loading ? (
          <Loading />
        ) : posts ? (
          <Flex
            w="100%"
            flexWrap="wrap"
            gap={{
              base: '8px',
              md: '32px',
            }}
          >
            {posts.map((post, x: number) => {
              // eslint-disable-next-line react/no-array-index-key
              return (
                <Flex
                  flex={{
                    base: '1 0 100%',
                    md: '1 0 calc(50% - 16px)',
                  }}
                >
                  <BlogPostSnippet post={post} key={x} />
                </Flex>
              );
            })}
          </Flex>
        ) : null}
      </Container>
    </>
  );
};

export default BlogTag;
