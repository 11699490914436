import { Box, useBreakpointValue } from '@chakra-ui/react';

import DesktopMidPageBanner from './DesktopComponents/DesktopMidPageBanner';
import MobileMidPageBanner from './MobileComponents/MobileMidPageBanner';

const MidPageBanner = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box mt="175px" mb="20px">
      {isMobile ? (
        <MobileMidPageBanner bg="matisseBlue" />
      ) : (
        <Box mb={0} mt={0}>
          <DesktopMidPageBanner bg="matisseBlue" />
        </Box>
      )}
    </Box>
  );
};

export default MidPageBanner;
