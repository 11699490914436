import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { IconContext } from '@react-icons/all-files/lib';
import { FaUser } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';

import ImageComponent from '../Sanity/ImageComponent';
import { SalesProps } from '../Sanity/Model';

interface SalesTeamProps {
  sales: SalesProps;
}

const SalesTeam = ({ sales }: SalesTeamProps) => {
  const history = useHistory();
  const jump = (name: string) => {
    history.push(`/about-us/our-team?name=${name}`);
  };
  return (
    <>
      <Flex
        display={sales.hide ? 'none' : 'flex'}
        flexDir="column"
        justifyContent="flex-start"
        alignItems="center"
        gap={0}
        w="25%"
        mb={10}
      >
        {sales.image ? (
          <Flex onClick={() => jump(sales.slug.current)} cursor="pointer" justifyContent="center">
            <ImageComponent forceWidth="90%" width={90} sync isInline={false} value={sales.image} />
          </Flex>
        ) : (
          <Box
            width="80px"
            height="80px"
            background="#fafafa"
            borderRadius="50%"
            textAlign="center"
            lineHeight="80px"
          >
            <IconContext.Provider
              value={{
                color: '#ccc',
                size: '48',
              }}
            >
              <FaUser />
            </IconContext.Provider>
          </Box>
        )}
        <Flex
          flexDir="column"
          flex={1}
          alignContent="center"
          justifyContent="center"
          textAlign="center"
        >
          <Link
            fontSize={{
              base: 18,
              md: 22,
              lg: 24,
            }}
            lineHeight={{
              base: '24px',
            }}
            fontWeight={600}
            onClick={() => jump(sales.slug.current)}
          >
            {sales.name} {sales.hide}
          </Link>
          <Text fontSize={14} color="black">
            {sales.title}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default SalesTeam;
