import { Box, Center, Flex, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Container, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { GoogleReviews } from '../../assets/Images';
import BtnGetStarted from '../../components/BtnGetStarted';
import LogoLink from '../../components/LogoLink';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import GetStarted from '../../components/ui/GetStarted/GetStarted';
import CustomerReview from '../../components/ui/reviews/CustomerReview';
import CustomerReviewsSwiper from '../../components/ui/reviews/CustomerReviewsSwiper';
import { BBBStars, FacebookLogoLink } from '../../components/ui/reviews/ReviewStars';
import VideoReviewCarousel from '../../components/ui/reviews/VideoCarousel/VideoReviewCarousel';
import { GOOGLE } from '../../constants/urls';
import { Review, useGetVisibleReviewsQuery } from '../../gql/generated/graphql';
import useDevice, { DevicesEnum } from '../../hooks/useDevice';
import { NonNullRequired } from '../../utils/types/typeHelpers';

const ReviewsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem auto 0 auto;
  width: 100%;
  justify-content: space-around;

  @media only screen and ${({ theme }) => theme.device.lg} {
    width: 50%;
  }
`;

const BBBAndFacebookRow = styled(ReviewsContainer)`
  margin: 4rem auto 1rem auto;

  @media only screen and ${({ theme }) => theme.device.md} {
    margin: 5rem auto;
  }
`;

export type RequiredReview = NonNullRequired<Review>;

const Reviews = () => {
  const isMobile = useDevice() === DevicesEnum.mobile;
  const [columnOne, setColumnOne] = useState<RequiredReview[]>([]);
  const [columnTwo, setColumnTwo] = useState<RequiredReview[]>([]);
  const { data, loading } = useGetVisibleReviewsQuery();

  useEffect(() => {
    if (!data?.visibleReviews) {
      return;
    }

    const tempColumnOne: RequiredReview[] = [];
    const tempColumnTwo: RequiredReview[] = [];
    for (let i = 0; i < data.visibleReviews.length; i += 2) {
      if (data.visibleReviews[i]) {
        tempColumnOne.push(data.visibleReviews[i] as RequiredReview);
      }
      if (i !== data.visibleReviews.length - 1 && data.visibleReviews[i + 1]) {
        tempColumnTwo.push(data.visibleReviews[i + 1] as RequiredReview);
      }
    }

    setColumnOne(tempColumnOne);
    setColumnTwo(tempColumnTwo);
  }, [data]);

  return (
    <Container textAlign="center">
      <TitleContainer>
        <Title fontSize={{ base: '36px', md: '52px' }}>
          Feel the Love with Lease End’s Reviews.
        </Title>
      </TitleContainer>
      <Subtitle>
        We empower drivers with the technology to easily and securely exit their auto lease — with
        live, U.S.-based customer support and without getting ripped off by their dealer. We could
        talk all day about how great we are, but sometimes it's best to hear it directly from
        drivers just like you.
      </Subtitle>

      {!isMobile && (
        <>
          <BBBAndFacebookRow>
            <BBBStars width="150px" />
            <LogoLink
              src={GoogleReviews}
              link={GOOGLE}
              size="75px"
              mb="2"
              ariaLabel="Google Reviews Link"
            />
          </BBBAndFacebookRow>
          <BtnGetStarted mb={12} mt={-8} />
        </>
      )}
      {isMobile && !!columnOne.length && !!columnTwo.length && (
        <Box color="royalblue" p="2rem 0">
          &#171; Swipe to see more &#187;
        </Box>
      )}
      <Heading
        fontWeight="semibold"
        fontSize={{ base: 25, md: 35 }}
        textAlign="center"
        color="leaseEndBlue"
        mb={10}
      >
        Find out why these drivers love Lease End.
      </Heading>
      <VideoReviewCarousel />
      {isMobile ? (
        <CustomerReviewsSwiper />
      ) : (
        <>
          <Flex mb="5rem" justifyContent="space-evenly">
            {loading && <Loader active inline size="medium" />}
            {!loading && (
              <>
                <Box display="block" w={{ md: '50%' }}>
                  {columnOne.map((review) => (
                    <CustomerReview key={review.id} reviewer={review} desktopMargin="3rem auto">
                      {review.review}
                    </CustomerReview>
                  ))}
                </Box>
                {!!columnTwo.length && (
                  <>
                    <Box borderLeft="2px solid" borderColor="grayBackground" />
                    <Box display="block" w={{ md: '50%' }}>
                      {columnTwo.map((review) => (
                        <CustomerReview key={review.id} reviewer={review} desktopMargin="3rem auto">
                          {review.review}
                        </CustomerReview>
                      ))}
                    </Box>
                  </>
                )}
              </>
            )}
          </Flex>
        </>
      )}
      {isMobile && (
        <>
          <BBBAndFacebookRow>
            <BBBStars />
            <FacebookLogoLink />
          </BBBAndFacebookRow>
          <BtnGetStarted />
        </>
      )}
      <TitleContainer mt={{ base: '50px', lg: '20px' }}>
        <Title as="h2">See? {isMobile && <br />} People really love us!</Title>
      </TitleContainer>
      <Subtitle>
        Need help with a lease buyout? Get started now by entering your info below.
      </Subtitle>
      <Center mb="3rem" mt="5rem">
        <GetStarted />
      </Center>
    </Container>
  );
};

export default Reviews;
