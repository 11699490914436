import { Container, Link, ListItem, Text, TextProps, UnorderedList } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import Description from '../components/Description';
import Title from '../components/Title';
import TitleContainer from '../components/TitleContainer';
import config from '../config';
import { LDFlags } from '../constants/experiments';
import {
  GOOGLE_ADS,
  GOOGLE_AUTH,
  GOOGLE_DISPLAY_ADS,
  GOOGLE_PRIVACY,
  GOOGLE_PRIVACY_PARTNERS,
  GO_OPT_OUT,
  NETWORK_AD,
  OPT_OUT,
} from '../constants/urls';
import { useFlag } from '../hooks';

const SectionLabel = ({ children }: TextProps) => (
  <Text
    fontSize="20px"
    mb="5px"
    color="leaseEndBlue"
    fontWeight="semibold"
    letterSpacing="1px"
    textTransform="uppercase"
  >
    {children}
  </Text>
);

const StyledDescription = ({ children }: TextProps) => (
  <Description m="10px 0 20px 10px">{children}</Description>
);

const PrivacyPolicy = () => {
  const optOutEnabled = useFlag(LDFlags.LEASE_END_OPT_OUT);

  return (
    <Container maxW="1150px" mx="auto" mb="100px">
      <TitleContainer>
        <Title fontSize={{ base: '36px', md: '52px' }}>Privacy Policy</Title>
      </TitleContainer>
      <Text fontSize="16px">Last Updated: 4/22/2024</Text>
      <StyledDescription>
        Thank you for visiting our Lease End website, including our mobile device-ready version.
        Lease End and our affiliates (together, "Lease End" or "we") strive to make our website a
        useful tool and a rewarding experience for our customers by tailoring the content to your
        personal needs and interests. This way, the content of our website will be as relevant as
        possible for you, the customer. We hope that you find our website to be a useful part of
        your car-buying experience.
      </StyledDescription>
      <StyledDescription>
        To accomplish this, we responsibly collect and utilize personally identifiable information.
        We respect your privacy, and this Privacy Policy documents our commitment to safeguard and
        treat your privacy and the personal information that we collect online with reasonable care
        and in strict compliance with applicable law for the protection of personally identifiable
        information.
      </StyledDescription>
      <StyledDescription>
        This policy was implemented as of September 23, 2014 and applies to information collected on
        this website and those of our affiliates (together, "our website" or "the website") and
        covers the following areas:
      </StyledDescription>
      <UnorderedList ml="50px" fontSize={{ base: '14px', md: '16px' }}>
        <ListItem>
          What personally identifiable information is collected by Lease End or by any third party
          through our website;
        </ListItem>
        <ListItem>How Lease End uses this information;</ListItem>
        <ListItem>With whom Lease End may share this information;</ListItem>
        <ListItem>
          What choices are available to you as a user of our website with respect to collection, use
          and distribution of the information; and
        </ListItem>
        <ListItem>
          What types of security procedures are in place to protect the loss, misuse or alteration
          of information under our control.
        </ListItem>
      </UnorderedList>
      <StyledDescription>
        This Privacy Policy applies only to our website and information collected for us or by us
        through our website and through various features and online offerings on our website. It
        does not apply to any third party site or service linked to our website or recommended or
        referred by our website or by our staff. Further, it does not apply to any other type of
        online site or online service operated by Lease End or its affiliates, or to any of our
        offline activities.
      </StyledDescription>
      <SectionLabel>1. Information Collection and Use</SectionLabel>
      <StyledDescription>
        Lease End collects two different types of information through our website: anonymous
        information and personally identifiable information.
      </StyledDescription>
      <StyledDescription>
        Anonymous Information. First, we collect and store anonymous, aggregate information (such as
        internet protocol (IP) addresses, browser types, search engines, internet service provider
        (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks) from all
        visitors to our website. You may search our website for car models, prices and other
        information provided through our website without ever submitting your name, email address or
        any other personally identifiable information. The anonymous information that we do collect
        from your visit is never linked to any of your personally identifiable information until you
        voluntarily submit that personal information, in which case some otherwise anonymous
        information may be connected to your website activity and personal account. Otherwise, the
        anonymous information is only used in the aggregate to analyze trends, administer the
        website, diagnose any problems, track a visitor's movement in the aggregate, and gather
        broad demographic information for aggregate use. We may provide this anonymous information
        in aggregate form to other parties or use it for our own insight or marketing purposes. Our
        ability to use this information is not restricted in any way.
      </StyledDescription>
      <StyledDescription>
        We also use "cookies" and "image tags" to collect certain usage information from all
        customers and visitors to our website. A "cookie" is a small text file that a website can
        place on your computer's hard drive or on your mobile device's memory in order to collect
        information about your activities on our website. An "image tag," also known as a clear GIF
        or web beacon, works together with cookies and is a small image file that may be located in
        various areas of our website. Most browsers automatically accept cookies, but you have the
        option to change your browser setting to decline them.
      </StyledDescription>
      <StyledDescription>
        Accepting cookies in no way gives us access to your computer or any personal information
        about you, since cookies are not used to identify anonymous website visitors. Our cookies
        enable you to proceed smoothly through our website and know whether you've used it before,
        so as to eliminate some steps that apply to new visitors. Cookies also give us usage data
        like how often you visit, where you go on our website, and what you do while here.
      </StyledDescription>
      <StyledDescription>
        Similarly, a cookie may be placed by our third-party advertising companies. These companies
        may use aggregated statistics about your use of our website and third party media in order
        to provide you with advertisements about goods and services that you may be interested in.
        The information they collect does not include your personal information.
      </StyledDescription>
      <StyledDescription>
        The third-party advertising companies may also employ technology that is used to measure the
        effectiveness of ads. Any such information is anonymous. They may use this anonymous
        information about your visits to this and other sites in order to provide advertisements
        about goods and services of potential interest to you. No personal information about you is
        collected during this process. The information is anonymous and does not link online actions
        to an identifiable person.
      </StyledDescription>
      <StyledDescription>
        Lease End partners with third-party advertising company Google to collect information about
        how users navigate our website and we use many or all of their products to enhance our
        website experience. Like Lease End, Google is very much committed to consumer protection, to
        maintaining the privacy of your personal information and to making ads safe, unobtrusive and
        relevant for you.
      </StyledDescription>
      <StyledDescription>
        Google uses this information only to generate anonymous visitor profiles, which do not
        contain personally identifiable information. These anonymous profiles are used to provide
        you content specifically tailored to you. To learn more about how Google collects and
        manages information, or to opt out of Google's collection of information about how you
        navigate our website, please go to{' '}
        <a target="_blank" rel="noreferrer" href={GOOGLE_PRIVACY}>
          {GOOGLE_PRIVACY}
        </a>
        .
      </StyledDescription>
      <StyledDescription>
        Google's technologies and products, including AdSense, AdWords, Google Analytics, and a
        range of DoubleClick-branded services, also use cookies. When you visit a page that uses one
        of these products, various cookies may be sent to your browser.
      </StyledDescription>
      <StyledDescription>
        Among the Google products we use is Google Analytics, a web analytics service provided by
        Google. Information generated by cookies about your use of our website (including your
        shortened IP address) is transmitted to a Google server in the U.S. and stored there. Google
        uses this information to evaluate your use of our website, compile reports on website
        activity for our website operators and to provide other services related with the website
        and internet use. Google may also transfer this information to third parties if required by
        law, or where third parties process these data on behalf of Google. For more information
        about Google Analytics and how it collects and processes data, please go to "How Google uses
        data when you use our partners' sites or apps", located at{' '}
        <a target="_blank" rel="noreferrer" href={GOOGLE_PRIVACY_PARTNERS}>
          {GOOGLE_PRIVACY_PARTNERS}
        </a>
        . To opt out of Google Analytics, please go to{' '}
        <a target="_blank" rel="noreferrer" href={GO_OPT_OUT}>
          {GO_OPT_OUT}
        </a>
        .
      </StyledDescription>
      <StyledDescription>
        To help us manage our advertising and website, Google offers many products in addition to
        Google Analytics, including Google's Analytics for Display Advertisers, AdSense, AdWords,
        and a range of DoubleClick-branded services. Lease End participates in an integrated version
        of Google Analytics and DoubleClick products or services and/or other Google display ad
        products or services, known as Google Analytics for Display Advertisers. As with Google
        Analytics, you can learn more about Google Analytics for Display Advertisers, or you can opt
        out, by going to{' '}
        <a target="_blank" rel="noreferrer" href={GO_OPT_OUT}>
          {GO_OPT_OUT}
        </a>
        .
      </StyledDescription>
      <StyledDescription>
        We have or may enable interest-based advertising features, including remarketing, with
        Google Analytics in connection with other Google services. This kind of advertising based on
        consumer interests and your precise geographic location may, in addition to remarketing,
        include Google's Adword product features known as: interest categories, similar audiences,
        other types of interest-based advertising and demographic and location targeting.{' '}
        <a target="_blank" rel="noreferrer" href={GOOGLE_ADS}>
          DoubleClick advertising cookies
        </a>{' '}
        or mobile advertising identifiers, associated with your use of our website, are or may be
        employed by us in all aspects of remarketing in online advertising.
      </StyledDescription>
      <StyledDescription>
        "Remarketing" is simply a feature of interest-based advertising that lets us reach people
        who have previously visited our website. Remarketing helps us match interested people, like
        you, with a relevant message on our website or even when you search on Google using our
        keywords, use Google products like Gmail, or visit other websites. We gather information
        through cookies for remarketing, and similar audiences feature in AdWords, simply to make
        your visits relevant and allow us both to pick up where we left off on your last visit.
      </StyledDescription>
      <StyledDescription>
        Our partners, including Google, use cookies to serve ads based on your past visits to our
        website and to show our ads on sites across the Internet. You can opt out of Google's use of
        cookies by visiting Google's{' '}
        <a target="_blank" rel="noreferrer" href={GOOGLE_AUTH}>
          Ads Settings
        </a>
        . Alternatively, we may use Google DoubleClick's remarketing pixels to participate in
        remarketing and create a remarketing list of potential customers (this involves adding a
        remarketing tag, a small snippet of code that we get from Gooogle's Ad Exchange, across all
        our website pages). In such case, instead of the previous link, you can opt out of
        DoubleClick's use of cookies by visiting the{' '}
        <a target="_blank" rel="noreferrer" href={GOOGLE_DISPLAY_ADS}>
          DoubleClick opt-out page
        </a>{' '}
        or the{' '}
        <a target="_blank" rel="noreferrer" href={NETWORK_AD}>
          Network Advertising Initiative opt-out page
        </a>
        . Finally, you can opt out of any other third-party advertising partner's use of cookies by
        visiting the{' '}
        <a target="_blank" rel="noreferrer" href={NETWORK_AD}>
          Network Advertising Initiative opt-out page
        </a>
        .
      </StyledDescription>
      <StyledDescription>
        With respect to unrelated, third party web sites, such as third party online retailers,
        advertisers, or unrelated sites that we may happen to link to, or web sites that link to our
        website, those third parties may collect personal information directly from you. The
        information policies and practices of these unrelated parties are not covered by this
        privacy policy. We do not control the privacy policies of third parties even if we may
        provide hyperlinks or other access to their web sites. We are not responsible or liable for
        their independent policies and practices. Please review their privacy policies and
        practices. It is your responsibility to review them and decide if you are satisfied with
        their protections.
      </StyledDescription>
      <StyledDescription>
        Some browsers have a "do not track" feature that lets you tell websites that you do not want
        to have your online activities tracked. These features are not yet uniform, so we are not
        currently set up to respond to those signals.
      </StyledDescription>
      <StyledDescription>
        Personally Identifiable Information. Second, we require the submission of certain personally
        identifiable information when you use some services available on our website. For example,
        your use of your user name and password allows us to identify you as our customer who is
        seeking information in connection with our services. Our website uses a variety of contact
        forms and tracking phone numbers for visitors to request information, products, and
        services. We collect a visitor's contact information (like his or her email address or phone
        number) and in some cases, demographic information (like his or her zip code). In limited
        circumstances, such as when a visitor fills out an online credit application, we collect
        unique identifiers (like a social security number), and/or financial information (like age,
        income level, bank account or credit card numbers). We use this information to get in touch
        with our prospective customers about their requests, and to send them additional information
        about our products and services.
      </StyledDescription>
      <StyledDescription>
        Note that once you have created an order or otherwise voluntarily provided personally
        identifiable information to us, we may use cookies to help identify you. Personally
        identifiable information received from you (such as IP address, phone number, email address,
        zip code, etc.) may be contained in or linked to a cookie. The only time that we receive
        such personally identifiable information is when you volunteer it by submitting it to us.
      </StyledDescription>
      <StyledDescription>
        We may use cookies with personally identifiable information so that our website can remember
        you, enhance your online visit with us, and provide you with the information you're most
        likely to need. For instance, cookies allow our website to remind you of your past vehicle
        search interests and website activity and to suggest similar products and services. In some
        cases, cookies may improve your shopping experience and we use information gained through
        cookies to track your website activity and to deliver you content specific information
      </StyledDescription>
      <StyledDescription>
        Any financial information we collect is used to check the qualifications of our prospective
        customers and bill for products and services ordered. If you apply for financing or wish to
        purchase or lease a car through our online system, we may collect personal information such
        as your name, address, driver's license number and state, social security number and other
        credit information. This information goes into a secure database and is used solely to
        provide the service you requested. The collection, use and storage of your financial
        information for credit checks, for arranging credit and loans or for purchasing products and
        services may be subject to additional privacy policies and if so, you will be notified of
        them at the time of any such collection, use or storage of your financial information as
        required by law.
      </StyledDescription>
      <StyledDescription>
        Our online surveys may ask visitors for contact information (like an email address or phone
        number) which they may voluntarily provide. We also may run contests on our website in which
        we ask visitors for similar contact information in order to participate. We use contact
        information from our surveys and contests to send information about products and services to
        these participating visitors.
      </StyledDescription>
      <SectionLabel>
        2. Our Use Restrictions on Your Personally Identifiable Information
      </SectionLabel>
      <StyledDescription>
        Lease End does not sell, rent, or share personally identifiable information to or with any
        third party not affiliated with or owned by Lease End, except service providers who may
        assist us in such areas as our promotions, credit checks, data storage and order processing
        (See Agents and Vendors below). Lease End will never give or sell your personally
        identifiable information to unaffiliated third parties to be used for the purposes of
        sending you unsolicited commercial offers, such as spam. We may, however, use your personal
        information to send you commercial offers in e-mails from Lease End or its affiliated
        companies, which do not relate to the products or services you have ordered. You may
        altogether avoid or opt out of these marketing campaigns (see "Opting Out" below).
      </StyledDescription>
      <StyledDescription>
        We may also need to contact you for purposes other than marketing, for example to provide
        notice about the status of our website or the specific services you have requested. We also
        use this information to the extent necessary to enforce our website terms of service and to
        prevent imminent harm to persons or property. You may not opt-out of these kinds of
        communications. Consistent with our change policy (see "Your Consent and Notification of
        Changes" below), we will post any updates or notices about material changes in our policies
        on collection and use of your personally identifiable information to this Privacy Policy
        page.
      </StyledDescription>
      <SectionLabel>3. Third Party Information About You</SectionLabel>
      <StyledDescription>
        Our website supplements the information that you provide with information that we obtain
        about you from third parties, such as credit bureaus and other sources. To the best of our
        knowledge, we only deal with reputable providers who gather and maintain information in
        accordance with all applicable state and federal laws. Any such information will be
        maintained by us in accordance with the standards set forth in this Privacy Policy along
        with other personal information you've provided.
      </StyledDescription>
      <SectionLabel>4. Our Agents and Vendors</SectionLabel>
      <StyledDescription>
        Lease End may use other companies and/or individuals to perform any functions described in
        this Privacy Policy, on our behalf. Examples include marketing, credit checking, data and
        network hosting, service reminders, verification of sales, order processing and fulfillment,
        administering contact information and e-mail services and running special promotions. Such
        parties only have access to the personal information needed to perform these functions and
        may not use or store the information for any other purpose. We obtain commitments from these
        agents and vendors working on our behalf to refrain from using any information provided by
        Lease End to send you unsolicited e-mail messages from third parties or similar
        communications and from sharing your information with unrelated parties.
      </StyledDescription>
      <SectionLabel>
        5. Disclosure of Personal Information As Required By Law or Otherwise
      </SectionLabel>
      <StyledDescription>
        We will disclose personal information when required by law, or if we have a good-faith
        belief that such action is necessary to (a) comply with a current judicial proceeding, a
        court order or legal process served on us or to comply with the request of a law enforcement
        agency, (b) protect and defend our rights, or (c) protect the rights, property, and other
        interests of our users or others.
      </StyledDescription>
      <SectionLabel>6. Links</SectionLabel>
      <StyledDescription>
        Our website may contain links to websites not operated by us, as well as third party widgets
        and applications. Once you leave our website, we suggest that you review the applicable
        privacy policy of the third party website or application. Lease End is not responsible for
        the privacy practices or content of these other sites. We encourage you to be aware when you
        leave our website and to read the privacy statements of each and every site that collects
        personally identifiable information from you. Our Privacy Policy applies solely to
        information collected by our website.
      </StyledDescription>
      <SectionLabel>7. Security</SectionLabel>
      <StyledDescription>
        Lease End takes commercially reasonable precautions to protect your personally identifiable
        information. For example, we encrypt communications through our website with commercial
        strength encryption generally used by our industry. However, given the nature of the
        Internet and the fact that network security measures are not perfect, we cannot guarantee
        the absolute security of your information. If we are required to provide notice to you of a
        data security breach, the notice will be provided in electronic form.
      </StyledDescription>
      <SectionLabel>8. Your Consent and Notification of Changes</SectionLabel>
      <StyledDescription>
        By using any of our website or submitting information to us online, you agree that we may
        collect and use this information as stated in this Privacy Policy at the time we collect the
        information. If we decide to change our Privacy Policy in any material way, for a reasonable
        time we will post a concurrent notice to this Privacy Policy page on our website to alert
        you about the change in our privacy and information collection practices. In addition, if we
        make any material changes in our privacy practices that affect your personally identifiable
        information already stored in our database, we apply those changes to the personally
        identifiable information in our possession only as permitted by applicable law.
      </StyledDescription>
      <SectionLabel>9. Opting Out</SectionLabel>
      <StyledDescription>
        Visitors and customers have the opportunity to opt-out of receiving specific types of
        communications from us and our partners, such as e-mail, by not providing personally
        identifiable information at the point where our website requests such information about you.
        In addition, you may unsubscribe or opt out of all future e-mails from us, that do not
        relate to the products and services you have ordered, by clicking the unsubscribe link on
        any email marketing communication you receive.
        {optOutEnabled && (
          <>
            {' '}
            Furthermore, you can visit{' '}
            <Link as={ReactRouterLink} to={OPT_OUT}>
              {config.baseUrl}
              {OPT_OUT}
            </Link>{' '}
            to manage communication preferences and opt out of different communication methods.
          </>
        )}
      </StyledDescription>
      <SectionLabel>10. Further Information about Our Privacy Policy and Practices</SectionLabel>
      <StyledDescription>
        This Privacy Policy applies to all visitors and customers of our website. We may delete any
        or all of your information at any time without notice to you for any reason or no reason
        unless otherwise required by law to retain it. You may have other privacy protections under
        state laws and we will comply with any applicable state laws when we disclose information
        about you. This Privacy Policy replaces any previous policy on this topic and any statements
        or disclosures to you about our information practices.
      </StyledDescription>
    </Container>
  );
};

export default PrivacyPolicy;
