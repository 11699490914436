import { Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

import { CircleStep } from '../howItWorks/components/FourSteps';

type Props = {
  icon?: IconType;
  stepNumber?: number;
  stepPosition?: string;
  title: string;
  children: string;
  fontColor?: string;
};

const Step = ({
  icon,
  stepNumber = 1,
  title,
  children,
  stepPosition = 'start',
  fontColor = 'gray.800',
}: Props) => {
  return (
    <Flex alignItems="center" my="3" color={fontColor}>
      {icon ? (
        <Flex p="18px" bg="royalBlue" borderRadius="full">
          <Icon as={icon} w={8} h={8} color="white" m="auto" />
        </Flex>
      ) : (
        <CircleStep step={stepNumber} fontSize="22px" size="md" alignSelf={stepPosition} />
      )}
      <Flex textAlign="left" alignItems="initial" ml="8" flexDirection="column">
        <Text fontWeight="semibold" fontSize="large">
          {title}
        </Text>
        <Text>{children}</Text>
      </Flex>
    </Flex>
  );
};

export default Step;
