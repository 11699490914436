/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

import {
  CivicGuy,
  GoogleReviews,
  Logo,
  NSGGuy,
  NoDealership,
  OverMileage,
  TrustPilotIcon,
  VehicleDamage,
} from '../../assets/Images';
import Footer from '../../components/Footer';
import LicensePlateOrVinInput_LPSimple from '../../components/LicensePlateOrVinInput_LPSimple';
import LogoLink from '../../components/LogoLink';
import Title from '../../components/Title';
import { LDFlags } from '../../constants/experiments';
import { GOOGLE, HOME, TRUSTPILOT } from '../../constants/urls';
import { useFlag } from '../../hooks';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';

interface BenefitProps {
  image: string;
  tag: string;
}

const Benefit = ({ image = '', tag = '' }: BenefitProps) => {
  return (
    <>
      <Flex
        flex={1}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        background="#f7f7f7"
        borderRadius={8}
        p={4}
        px={8}
        gap={3}
      >
        <Text textTransform="capitalize" fontSize={16} fontWeight={800} color="#000">
          {tag}
        </Text>
        <Image src={image} h="100px" />
      </Flex>
    </>
  );
};

const LPSimple = () => {
  const EXP76 = useFlag(LDFlags.EXP76);
  const EXP84 = useFlag(LDFlags.EXP84);
  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'EXP-76',
      value: EXP76,
    });
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'EXP-84',
      value: EXP84,
    });
  };

  useEffect(() => {
    tracking();
  }, []);
  return (
    <>
      <Container maxW="1200px">
        <Flex
          justifyContent={{
            base: 'space-between',
          }}
          alignItems="center"
          py={{
            base: 0,
            md: '24px',
          }}
          pt={{
            base: 4,
            md: '24px',
          }}
        >
          <Flex>
            <Link href={HOME}>
              <Image
                src={Logo}
                w={{
                  base: '120px',
                  md: '180px',
                }}
              />
            </Link>
          </Flex>
          <Flex
            justifyContent="flex-end"
            alignItems="flex-start"
            gap={{
              base: 0,
              md: 8,
            }}
          >
            <Link
              href="tel:8449022842"
              mt={{
                base: 0,
                md: '6px',
              }}
            >
              <Text
                textAlign="right"
                fontSize={{
                  base: 18,
                  md: 24,
                }}
                fontWeight={800}
                color="#002d5d"
                lineHeight={1}
                letterSpacing="-0.5px"
              >
                (844) 902-2842
              </Text>
            </Link>
            <LogoLink
              src={GoogleReviews}
              link={GOOGLE}
              size={{
                base: '40px',
              }}
              alt="Lease End's reviews from Google."
              ariaLabel="Lease End's reviews from Google."
              borderStartWidth={1}
              ps={8}
              display={{
                base: 'none',
                md: 'block',
              }}
            />
            <LogoLink
              src={TrustPilotIcon}
              link={TRUSTPILOT}
              size={{
                base: '40px',
              }}
              alt="Lease End's reviews from Trustpilot."
              ariaLabel="Lease End's reviews from Trustpilot."
              display={{
                base: 'none',
                md: 'block',
              }}
            />
          </Flex>
        </Flex>
      </Container>
      <Container
        maxW="1000px"
        mt={4}
        background={{
          base: 'linear-gradient(180deg, rgba(186,244,255,1) 0%, rgba(186,244,255,0.5) 5%, rgba(255,255,255,1) 50%, rgba(186,244,255,0.5) 95%, rgba(186,244,255,1) 100%)',
          md: 'none',
        }}
        pb={16}
      >
        <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
          <Flex
            flexDir="column"
            justifyContent={{
              base: 'center',
              md: 'flex-start',
            }}
            flex={1}
            alignItems="flex-start"
            mt={{
              base: 8,
              md: 0,
            }}
          >
            <Title
              fontSize={{
                base: 48,
                md: 56,
              }}
              lineHeight={1.2}
              fontWeight={800}
              color="#000"
              textAlign={{
                base: 'center',
                md: 'left',
              }}
              m={0}
              p={0}
            >
              End your lease, keep your car & avoid the dealership
            </Title>
            <Flex mt="32px">
              <Title
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                color="#000"
                fontSize={{
                  base: 20,
                  md: 24,
                }}
                m={0}
                p={0}
                fontWeight={500}
                lineHeight={1.4}
              >
                We've helped more than{' '}
                <Text as="span" fontWeight={800}>
                  27,000 people
                </Text>{' '}
                end their lease and keep their car!
              </Title>
            </Flex>
            <Box
              mt="32px"
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              <Text mt="32px" fontSize={16} fontWeight={400}>
                End your car lease 100% online with Lease End – no haggling, no negotiating, and no
                extra fees. We take care of your titling, too, so you can skip the lines at the DMV.
              </Text>
              <Text pt="22px" fontSize={16} fontWeight={400}>
                Don’t let the auto industry push you into going to the dealership or DMV when you
                don’t have to.
              </Text>
            </Box>
            <Flex
              mt={{
                base: '32px',
                md: '48px',
              }}
              flexDir="row"
              flex={1}
              w="100%"
              alignItems="flex-start"
              gap={8}
            >
              <LicensePlateOrVinInput_LPSimple />
              <Image
                display={{
                  sm: 'none',
                  md: 'block',
                }}
                src={EXP84 ? NSGGuy : CivicGuy}
                w={{
                  sm: '0',
                  md: '350px',
                  lg: '450px',
                }}
                mt={EXP84 ? 0 : '-58px'}
              />
            </Flex>
            <Flex
              display={{
                base: 'flex',
                md: 'none',
              }}
              w="100%"
              mt={8}
            >
              <Image src={EXP84 ? NSGGuy : CivicGuy} w="100%" />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          mt={{
            base: 8,
            md: 16,
          }}
          pt={{
            base: 8,
            md: 0,
          }}
          borderTopWidth={{
            base: 1,
            md: 0,
          }}
          justifyContent="space-between"
          gap={3}
          flexDir={{
            base: 'column-reverse',
            md: 'row',
          }}
        >
          <Benefit image={NoDealership} tag="No hassle at the dealership" />
          <Benefit image={OverMileage} tag="No fees for too many miles!" />
          <Benefit image={VehicleDamage} tag="No charge for damages!" />
        </Flex>
      </Container>
      <Footer
        _simpleLP
        pt={{
          base: 0,
          md: 8,
        }}
        borderTopWidth={{
          base: 0,
          md: 1,
        }}
      />
    </>
  );
};

export default LPSimple;
