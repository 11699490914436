/* eslint-disable react/no-array-index-key */
import { Box, Flex, Image, Link } from '@chakra-ui/react';
import { createBrowserHistory } from 'history';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LearnLogo } from '../../assets/Images';
import { LDFlags } from '../../constants/experiments';
import { BLOG, BLOG_SANITY } from '../../constants/urls';
import { useFlag } from '../../hooks';
import { blogMenuFetch } from '../Sanity/Data';
import { CategoryProps } from '../Sanity/Model';

const Nav = () => {
  const SANITY_BLOG = useFlag(LDFlags.SANITY_BLOG);
  const history = createBrowserHistory({ forceRefresh: true });
  const [nav, setNav] = useState<CategoryProps[] | null>(null);
  const fetchNav = async () => {
    const data: CategoryProps[] | null = await blogMenuFetch();
    setNav(data);
  };
  const params: {
    tag: string;
  } = useParams();
  const jump = (slug?: string, isNav?: boolean) => {
    if (isNav) {
      history.push(`${SANITY_BLOG ? BLOG_SANITY : BLOG}/tag/${slug}`);
      return;
    }
    history.push(`${SANITY_BLOG ? BLOG_SANITY : BLOG}/${slug}`);
  };
  useEffect(() => {
    fetchNav();
  }, []);
  return (
    <>
      <Box
        display={{
          base: 'block',
          md: 'none',
        }}
        mb={5}
        onClick={() => jump('', false)}
        cursor="pointer"
      >
        <Image src={LearnLogo} w={130} />
      </Box>
      <Flex flexDir="row" justifyContent="flex-start" alignItems="center" w="100%" gap={4}>
        <Flex
          w="100%"
          display={{
            base: 'none',
            md: 'flex',
          }}
          gap={3}
        >
          <Image
            onClick={() => jump('', false)}
            src={LearnLogo}
            w={220}
            display={{
              base: 'none',
              md: 'block',
            }}
          />
          <Flex bg="oceanBoatBlue" h="56px" flex={1} borderRadius={4} justifyContent="space-around">
            {nav &&
              nav.map((n, x: number) => {
                return (
                  <Link
                    onClick={() => jump(n.title, true)}
                    key={`le-nav-${x}`}
                    fontSize={16}
                    fontWeight={600}
                    color="white"
                    lineHeight="52px"
                    borderRadius={4}
                    backgroundColor={params.tag === n.title ? 'leaseEndBlue' : 'transparent'}
                    _hover={{
                      backgroundColor:
                        params.tag === n.title ? 'leaseEndBlue' : 'rgba(255, 255, 255, 0.18)',
                      color: 'white',
                    }}
                    flex={1}
                  >
                    {n.title}
                  </Link>
                );
              })}
          </Flex>
        </Flex>
        <Box
          display={{
            base: 'block',
            md: 'none',
          }}
        >
          <Flex gap={1} justifyContent="flex-start" flexWrap="wrap">
            {nav &&
              nav.map((n, x: number) => {
                return (
                  <Link
                    bg={params.tag === n.title ? '#002d5d' : 'oceanBoatBlue'}
                    onClick={() => jump(n.title, true)}
                    key={`le-nav-${x}`}
                    fontSize={13}
                    fontWeight={params.tag === n.title ? 700 : 500}
                    color="white"
                    p={1}
                    px={2}
                    borderRadius={4}
                    _hover={{
                      backgroundColor: 'leaseEndBlue',
                      color: 'white',
                    }}
                  >
                    {n.title}
                  </Link>
                );
              })}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Nav;
