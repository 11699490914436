import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BUY_YOUR_LEASED_CAR } from '../constants/urls';

interface ReactNodesObject {
  homeHero: {
    title: React.ReactNode;
    copy: React.ReactNode;
  };
  steps: {
    title: React.ReactNode;
    descriptions: React.ReactNode[];
  };
  howCanWeHelp: {
    description: React.ReactNode;
  };
}

const baseVariation: ReactNodesObject = {
  homeHero: {
    title: (
      <>
        End your lease, <Text as="br" display={{ md: 'none' }} /> keep your car.
      </>
    ),
    copy: (
      <>
        We've helped more than <span style={{ fontWeight: '800' }}>27,000</span> people
        <Text as="br" display={{ md: 'none' }} /> end their lease and keep their car.
      </>
    ),
  },
  steps: {
    title: "Only 12 minutes to get started? You heard us right - here's how it works.",
    descriptions: ['', '', '', ''],
  },
  howCanWeHelp: {
    description: '',
  },
};

const buyYourLeasedCarVariation: ReactNodesObject = {
  homeHero: {
    title: 'Buy Your Leased Car Today',
    copy: (
      <>
        We've helped more than <span style={{ fontWeight: '700' }}>27,000</span> drivers{' '}
        <Text as="br" display={{ md: 'none' }} /> end their lease and buy their car.
      </>
    ),
  },
  steps: {
    title: 'How to Buy Your Leased Car',
    descriptions: [
      'We’ll ask some easy questions about you & your lease to prepare for your purchase.',
      'Review your unique financing and customizable vehicle coverage options.',
      'We’ll prepare the documents you need and you’ll eSign securely on your Lease End account.',
      'We’ll arrange titling, registration, & new plates for you so you can skip the DMV trip.',
    ],
  },
  howCanWeHelp: {
    description:
      "If you're here to do more research, we'll walk you through everything you need to know about buying your leased car.",
  },
};

const useHomePageVariations = (): ReactNodesObject => {
  const [variation, setVariation] = useState<ReactNodesObject>(baseVariation);

  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case BUY_YOUR_LEASED_CAR:
        setVariation(buyYourLeasedCarVariation);
        break;
      default:
        setVariation(baseVariation);
    }
  }, [pathname]);

  return variation;
};

export default useHomePageVariations;
