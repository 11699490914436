import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export interface CardProps extends FlexProps {
  center?: boolean;
  raised?: boolean;
}

const Card: React.FC<CardProps> = ({ children, raised, center, ...props }) => (
  <Flex
    justifyContent={center ? 'center' : 'space-between'}
    maxW={{ lg: '650px' }}
    mb={{ base: '20px', lg: '15px' }}
    w="100%"
    fontFamily="Monorale, arial, sans-serif"
    p={{ base: '18px', lg: '18px 70px' }}
    borderRadius="15px"
    position="relative"
    flexDir="column"
    minH={0}
    bg="white"
    transition="box-shadow .1s ease,transform .1s ease"
    boxShadow="0px 3px 6px #00000029"
    m="0 auto 20px"
    _hover={{
      transform: raised ? 'translateY(-3px)' : '',
      boxShadow: '0px 3px 6px #00000029',
    }}
    {...props}
  >
    {children}
  </Flex>
);

export default Card;
