/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Icon, Image, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';
import { MdOutlinePlayCircle } from 'react-icons/md';

import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import { Action } from './VideoReviewCarousel';

interface Props {
  name: string;
  isCurrent: boolean;
  handleAction: (action: Action) => void;
  isNextVideo: boolean;
}

interface ThumbnailProps {
  thumbnail: string;
  isCurrent: boolean;
}

const Thumbnail = ({ thumbnail, isCurrent }: ThumbnailProps) => {
  return (
    <>
      <Image
        src={thumbnail}
        style={{ filter: `brightness(${isCurrent ? 1 : 0.6})` }}
        zIndex={1}
        cursor="pointer"
        boxShadow={isCurrent ? 'xl' : 'none'}
        borderRadius="8px"
      />
    </>
  );
};

const VideoThumbnail = ({ name, handleAction, isCurrent, isNextVideo }: Props) => {
  const thumbnail = `/video-reviews/thumbnails/${name}-v2.png`;
  const video = `/video-reviews/videos/${name}.mp4`;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [doPlay, setDoPlay] = useState<boolean>(false);

  const handleClick = () => {
    // Clicking on middle thumbnail
    if (isCurrent) {
      setDoPlay(true);
      rudderanalytics.track(RudderEvent.VideoReviewStarted, {
        video: `${name}.mp4`,
      });
      return;
    }
    setDoPlay(false);
    // Clicking on right thumbnail
    if (isNextVideo) {
      handleAction('next');
      return;
    }
    // Clicking on left thumbnail
    handleAction('prev');
  };

  return (
    <Box position="relative" onClick={handleClick}>
      {isCurrent ? (
        doPlay ? (
          <video
            autoPlay
            controls
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            style={{
              border: '1px solid white',
              borderRadius: '5px',
              outline: isCurrent ? '1px solid #015FCC' : '', // trueBlue color - video component requires hex code
              background: 'white',
              height: isMobile ? 'auto' : '436.58px',
            }}
          >
            <source src={video} type="video/mp4" />
          </video>
        ) : (
          <Thumbnail thumbnail={thumbnail} isCurrent={isCurrent} />
        )
      ) : (
        <>
          <Thumbnail thumbnail={thumbnail} isCurrent={isCurrent} />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={2}
          >
            <Icon
              as={MdOutlinePlayCircle}
              color="white"
              opacity={0.75}
              display={isCurrent ? 'block' : 'none'}
              _hover={{ opacity: isCurrent ? 0.8 : 0.5 }}
              cursor={isCurrent ? 'pointer' : 'default'}
              fontSize="52px"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default VideoThumbnail;
