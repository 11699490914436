import { BBBLogo, GoogleReviews, TrustPilotIconWhite } from '../../assets/Images';
import { BBB, GOOGLE, TRUSTPILOT } from '../../constants/urls';
import LogoLink from '../LogoLink';

const LPLogos = () => {
  return (
    <>
      <LogoLink
        src={TrustPilotIconWhite}
        link={TRUSTPILOT}
        w="100px"
        alt="Lease End's reviews from Trustpilot."
        ariaLabel="Lease End's reviews from Trustpilot."
        m="0 auto"
        display={{
          base: 'none',
          md: 'block',
        }}
      />
      <LogoLink
        src={GoogleReviews}
        link={GOOGLE}
        w="100px"
        alt="Lease End's reviews from Google."
        ariaLabel="Lease End's reviews from Google."
        m="0 auto"
        display={{
          base: 'none',
          md: 'block',
        }}
      />
      <LogoLink
        src={BBBLogo}
        link={BBB}
        w="100px"
        alt="Lease End receives top ratings from Better Business Bureau"
        ariaLabel="Lease End receives top ratings from Better Business Bureau"
        m="0 auto"
      />
    </>
  );
};

export default LPLogos;
