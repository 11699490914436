import { Flex, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import Title from '../../components/Title';
import { LDFlags } from '../../constants/experiments';
import {
  ENDORSEMENTS,
  ENDORSEMENTS_EXPERTS,
  ENDORSEMENTS_NO_DEALER,
  ENDORSEMENTS_SAVED_MONEY,
  ENDORSEMENTS_SAVED_TIME,
} from '../../constants/urls';
import { useFlag } from '../../hooks';
import EndorsementIntro from './EndorsementIntro';
import EndorsementExpert from './EndorsementLike';
import EndorsementNoDealer from './EndorsementNoDealer';
import EndorsementSavedMoney from './EndorsementSavedMoney';
import EndorsementSavedTime from './EndorsementSavedTime';
import EndorsementsNav from './EndorsementsNav';

const Endorsements = () => {
  const MKT_ENDORSEMENTS = useFlag(LDFlags.MKT_ENDORSEMENTS);
  const { pathname } = useLocation();
  if (!MKT_ENDORSEMENTS) {
    return (
      <>
        <Title p={16}>Section not available.</Title>
      </>
    );
  }
  return (
    <>
      <Flex flex={1} maxW={1920} flexDir="column">
        <EndorsementsNav />
        {pathname === ENDORSEMENTS && <EndorsementIntro />}
        {pathname === ENDORSEMENTS_NO_DEALER && <EndorsementNoDealer />}
        {pathname === ENDORSEMENTS_EXPERTS && <EndorsementExpert />}
        {pathname === ENDORSEMENTS_SAVED_MONEY && <EndorsementSavedMoney />}
        {pathname === ENDORSEMENTS_SAVED_TIME && <EndorsementSavedTime />}
        {pathname === ENDORSEMENTS && (
          <>
            <Flex
              alignItems="center"
              flexDir={{
                base: 'column-reverse',
                md: 'row',
              }}
              flex={1}
              w="100%"
              justifyContent="space-between"
              p={8}
              borderRadius={8}
              mb={4}
              gap={4}
            >
              <Flex bg="#FFF" flexDir="column" justifyContent="space-around" flex={1} gap={4}>
                <Flex
                  flexDir="column"
                  flex={1}
                  borderWidth={1}
                  justifyContent="center"
                  borderRadius={8}
                  pt={4}
                  pb={5}
                >
                  <Title letterSpacing="0.5px" fontSize={44} fontWeight={800} color="black" m={0}>
                    $800M+
                  </Title>
                  <Text textAlign="center" color="#787878" fontWeight={600} fontSize={15}>
                    in loans secured
                  </Text>
                </Flex>
                <Flex
                  flexDir="column"
                  flex={1}
                  pt={4}
                  pb={5}
                  borderWidth={1}
                  justifyContent="center"
                  borderRadius={8}
                >
                  <Title letterSpacing="0.5px" fontSize={44} fontWeight={800} color="black" m={0}>
                    27,000+
                  </Title>
                  <Text textAlign="center" color="#787878" fontWeight={600} fontSize={15}>
                    auto leases ended
                  </Text>
                </Flex>
              </Flex>
              <Flex
                w={{
                  base: '100%',
                  md: '45%',
                }}
                bg="#FFF"
                borderWidth={1}
                borderColor="#aaa"
                height={230}
                borderRadius={8}
                pb={5}
                px={5}
                pt={2}
              >
                <Widget />
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default Endorsements;
