import { Box, Flex, Heading } from '@chakra-ui/react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import colors from '../../chakra/foundations/colors';

const Question = (props: { question: string; isOpen: boolean }) => {
  const { question, isOpen } = props;
  return (
    <>
      <Flex direction="row" justifyContent="space-between" flexGrow={1} pt={4} pb={4}>
        <Heading
          m={0}
          as="h3"
          size="md"
          fontWeight={isOpen ? 400 : 400}
          lineHeight={1.4}
          color={isOpen ? 'oceanBoatBlue' : 'oceanBoatBlue'}
          cursor="pointer"
        >
          {question}
        </Heading>
        <Box ms={8} mt={1}>
          {isOpen ? (
            <BsChevronUp size={20} fill="#888" />
          ) : (
            <BsChevronDown size={20} fill={colors.oceanBoatBlue} />
          )}
        </Box>
      </Flex>
    </>
  );
};
export default Question;
