import { Button } from '@chakra-ui/react';
import React from 'react';

import { FLOW_ENTRY } from '../constants/urls';

interface BtnGetStartedProps {
  mt?: number;
  mb?: number;
}

const BtnGetStarted: React.FC<BtnGetStartedProps> = ({ mt, mb }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const handleStart = () => {
    let params = '?from=reviews';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        params += `&${key}=${value}`;
      });
    }
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${FLOW_ENTRY + params}`, '_self');
  };
  return (
    <>
      <Button
        mt={mt || 0}
        mb={mb || 0}
        onClick={() => {
          handleStart();
        }}
      >
        GET STARTED
      </Button>
    </>
  );
};

export default BtnGetStarted;
