import { Box, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import { COMErrorCodes } from '../../constants/errors';
import { LDFlags } from '../../constants/experiments';
import { QueryParams } from '../../constants/queryParam';
import { BUY_YOUR_LEASED_CAR } from '../../constants/urls';
import useFlag from '../../hooks/useFlag';
import useRefSize from '../../hooks/useRefSize';
import { createErrorToast } from '../../utils/toast';
import CarDudeBgImage from './components/CarDudeBgImage';
import ExpiredPayoffRequestModal from './components/ExpiredPayoffRequestModal';
import FaqAndArticles from './components/FaqAndArticles';
import HomePageBannerAd from './components/HomePageBannerAd';
import HowCanWeHelp from './components/HowCanWeHelp';
import MidPageBanner from './components/MidPageBanner';
import MonthlyPayment from './components/MonthlyPayment';
import ReadyToBuyYourLeasedCar from './components/ReadyToBuyYourLeasedCar';
import ReviewLinks from './components/ReviewLinks';
import Reviews from './components/Reviews';
import Steps from './components/Steps';
import TellMeMoreAboutLeasedBuyouts from './components/TellMeMoreAboutLeasedBuyouts';
import UTMHero from './components/UTMHero';
import WhyBuyYourLeasedCar from './components/WhyBuyYourLeasedCar';

const MktgInstagram = () => {
  const homePageBannerAd = useFlag(LDFlags.HOME_PAGE_VALENTINES_BANNER);
  const { pathname } = useLocation();
  const [expiredPayoffRequestModalOpen, setExpiredPayoffRequestModalOpen] = useState(false);
  const [imgRef, { height }] = useRefSize<HTMLImageElement>();
  const {
    replace,
    location: { search },
  } = useHistory();
  const toast = useToast();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const errCode = params.get(QueryParams.ERR_CODE);
    if (errCode === COMErrorCodes.NoDealFound) {
      params.delete(QueryParams.ERR_CODE);
      replace({ search: params.toString() });
      toast(
        createErrorToast({
          description:
            "We weren't able to find any information associated with the email you " +
            'provided. Please enter your License Plate or VIN to get started. If you think this ' +
            'is an error, please get in touch with our support team for help.',
        }),
      );
    }
  }, [search]);

  return (
    <>
      <Box h="100%">
        <Nav />
        <CarDudeBgImage ref={imgRef} />
        <UTMHero h={height ? height - height * 0.165 : 910} />
        {homePageBannerAd && <HomePageBannerAd />}
        <Steps />
        <ReviewLinks />
        <HowCanWeHelp />
        {pathname === BUY_YOUR_LEASED_CAR && (
          <>
            <WhyBuyYourLeasedCar />
            <TellMeMoreAboutLeasedBuyouts />
            <ReadyToBuyYourLeasedCar />
            <MonthlyPayment />
          </>
        )}
        <MidPageBanner />
        <Reviews />
        {/* {pathname !== BUY_YOUR_LEASED_CAR && <MonthlyPayment />} */}
        <FaqAndArticles />
        <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
      </Box>
      <ExpiredPayoffRequestModal
        isOpen={expiredPayoffRequestModalOpen}
        onClose={() => setExpiredPayoffRequestModalOpen(false)}
      />
    </>
  );
};

export default MktgInstagram;
