import React from 'react';
import styled from 'styled-components';

const DarkLinkStyles = styled.span`
  &&& {
    display: inline-block;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.midnightBlueDark};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-family: ${({ theme }) => theme.fontFamilies.main};

    @media only screen and ${({ theme }) => theme.device.lg} {
      font-size: 16px;
    }
  }
`;

interface ButtonProps {
  target?: string;
  href: string;
  children: React.ReactNode;
}

export const DarkLink: React.FC<ButtonProps> = ({ target, href, children, ...rest }) => {
  return (
    <a target={target} href={href} {...rest}>
      <DarkLinkStyles>{children}</DarkLinkStyles>
    </a>
  );
};
