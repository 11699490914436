/* eslint-disable react/destructuring-assignment */
import { SanityImageSource, getImageDimensions } from '@sanity/asset-utils';
import urlBuilder from '@sanity/image-url';
import { useEffect, useState } from 'react';

import client from '../../client';

interface ImageComponentProps {
  value: SanityImageSource;
  isInline: boolean;
  flavor?: string;
  width?: number;
  forceWidth?: string;
  sync?: boolean;
}

export const ImageComponent = (props: ImageComponentProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const original: {
    width: number;
    height: number;
  } = getImageDimensions(props.value);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const size = () => {
    setIsPortrait(original.height > original.width);
    const max_dimension = props.width ? props.width : 800;
    if (isPortrait) {
      setHeight(max_dimension);
      setWidth(props.sync ? max_dimension : max_dimension * (original.width / original.height));
    } else {
      setWidth(max_dimension);
      setHeight(props.sync ? max_dimension : max_dimension * (original.height / original.width));
    }
  };
  useEffect(() => {
    if (props.value) {
      size();
    }
  }, [props]);
  return (
    <img
      src={urlBuilder(client)
        // eslint-disable-next-line react/destructuring-assignment
        .image(props.value)
        // eslint-disable-next-line react/destructuring-assignment
        .width(props.forceWidth ? 1000 : width)
        .height(props.forceWidth ? 1000 : height)
        .fit('max')
        .auto('format')
        .url()}
      alt=""
      loading="lazy"
      style={{
        display: props.isInline ? 'inline-block' : 'block',
        aspectRatio: width / height,
        borderRadius: props.flavor && props.flavor === 'snippet' ? 8 : '50%',
        padding: props.flavor && props.flavor === 'snippet' ? 4 : 0,
        border: props.flavor && props.flavor === 'snippet' ? '1px solid #aaa' : '',
        width: props.forceWidth ? `${props.forceWidth}` : `${width}px`,
        height: props.forceWidth ? `auto` : `${height}px`,
      }}
    />
  );
};

export default ImageComponent;
