import { Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import Title from '../components/Title';
import Card from '../components/ui/views/Card';
import Subtitle from '../components/Subtitle';
import { DarkLink } from '../components/DarkLink';
import { OurTeam, FactSheet, Logo, GreenCircleMountain } from '../assets/Images';
import Articles from '../components/ui/articles/Articles';
import { CONTACT_US_EMAIL } from '../constants/contact';

interface IStyledSubtitleProps {
  centered?: boolean;
  small?: boolean;
}

const StyledSubtitle = styled(Subtitle)<IStyledSubtitleProps>`
  font-size: ${(props) => (props.small ? '14px' : '18px')};
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  margin: 0;
  padding: 0;
  width: '100%';
`;

const StyledContainer = styled(Container)`
  padding-left: 10px;
  padding-right: 10px;
`;

const StyledCard = styled(Card)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const StyledAnchor = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 125px;
`;

interface ISpacerProps {
  size: number;
}

const Spacer = styled.div<ISpacerProps>`
  margin-top: ${({ size }) => (size ? `${size}px` : '0px')};
`;

const StyledImage = styled.img`
  width: 100px;
`;

const Press = () => {
  return (
    <StyledContainer textAlign="left">
      <Spacer size={10} />

      <Title>Press Resources</Title>
      <Spacer size={15} />
      <StyledSubtitle small>
        Below are some helpful links and downloads for you to help spread the word about us. Don’t
        see what you are looking for?{' '}
        <DarkLink href={`mailto: ${CONTACT_US_EMAIL}`}>Contact us</DarkLink> and we’ll help with
        anything you need.
      </StyledSubtitle>

      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid.Row stretched>
              <Spacer size={25} />
              <Grid stackable stretched>
                <Grid.Row stretched>
                  <Grid.Column width={5}>
                    <StyledCard>
                      <StyledAnchor href="https://leaseend-com-images.s3.us-east-2.amazonaws.com/BiosAndHeadshots.zip">
                        <img src={OurTeam} alt="" width={125} />
                      </StyledAnchor>
                    </StyledCard>
                    <StyledSubtitle centered>Our Team</StyledSubtitle>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <StyledCard>
                      <StyledAnchor
                        href="https://leaseend-com-images.s3.us-east-2.amazonaws.com/LeaseEndFactSheet.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <StyledImage src={FactSheet} alt="" />
                      </StyledAnchor>
                    </StyledCard>
                    <StyledSubtitle centered>Fact Sheet</StyledSubtitle>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <StyledCard>
                      <StyledAnchor href="https://leaseend-com-images.s3.us-east-2.amazonaws.com/LeaseEndMediaResources.zip">
                        <>
                          <img src={GreenCircleMountain} alt="" width={60} />
                          <StyledImage src={Logo} alt="" width={150} />
                        </>
                      </StyledAnchor>
                    </StyledCard>
                    <StyledSubtitle centered>Brand Assets</StyledSubtitle>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Articles />

      <Spacer size={40} />
      <Title as="h2">Our Mission</Title>
      <Spacer size={15} />
      <StyledSubtitle>
        Lease End empowers auto lease owners with the technology to easily exit their lease.
      </StyledSubtitle>
      <Spacer size={15} />
      <StyledSubtitle small>
        Our mission is to simplify the lease-end process and provide lease owners with the resources
        necessary to make an end-of-lease decision that’s truly best for them. Driven by innovative
        technology, we took the archaic lease-end process dealerships offer and turned it into a
        transparent, accessible solution that’s more intuitive to a modern consumer.
      </StyledSubtitle>
      <Spacer size={50} />
      <Spacer size={50} />
    </StyledContainer>
  );
};

export default Press;
