/* eslint-disable react/jsx-pascal-case */
import { HStack, useBreakpointValue } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LDFlags } from '../../../constants/experiments';
import { BEST_MONEY, HOME, KWLP_LEASE_BUYOUT_LOANS } from '../../../constants/urls';
import { useFlag } from '../../../hooks';
import useCheckFlowStatus from '../../../hooks/useCheckFlowStatus';
import { RudderEvent, rudderanalytics } from '../../../utils/rudderstack';
import LicensePlateOrVinInput from '../../LicensePlateOrVinInput';
import LicensePlateOrVinInput_LPRates from '../../LicensePlateOrVinInput_LPRates';
import BestMoneyPartnershipBadge from './BestMoneyPartnershipBadge';
import ContinueContainer from './ContinueContainer';

interface Props {
  autoFocus?: boolean;
}

const GetStarted = ({ autoFocus = false }: Props) => {
  const { pathname } = useLocation();
  const { inFlow, enterFlow, canStartOver, startOver } = useCheckFlowStatus();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const showPartnershipBadge = pathname === BEST_MONEY && !isMobile;

  const EXP85 = useFlag(LDFlags.EXP85);
  const tracking = () => {
    if (pathname === HOME) {
      rudderanalytics.track(RudderEvent.LabTest, {
        name: 'EXP-85',
        value: EXP85,
      });
    }
  };

  useEffect(() => {
    tracking();
  }, []);

  return (
    <>
      {inFlow ? (
        <ContinueContainer
          enterFlow={enterFlow}
          canStartOver={canStartOver}
          startOver={startOver}
        />
      ) : (
        <HStack pl={showPartnershipBadge ? '50px' : undefined} align="flex-start">
          {showPartnershipBadge && <BestMoneyPartnershipBadge />}
          {(pathname === HOME && EXP85) || pathname === KWLP_LEASE_BUYOUT_LOANS ? (
            <>
              <LicensePlateOrVinInput_LPRates autoFocus={autoFocus} />
            </>
          ) : (
            <LicensePlateOrVinInput autoFocus={autoFocus} />
          )}
        </HStack>
      )}
    </>
  );
};

export default GetStarted;
