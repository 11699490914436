import { Box, Flex, Image, Text } from '@chakra-ui/react';

import {
  EndorsementsBankLoop,
  EndorsementsLogo,
  EndorsementsNewVehicle,
} from '../../assets/Images';
import Widget from '../../components/LicensePlateOrVinInputEndorsement';
import EndorsementHeading from './EndorsementHeading';
import EndorsementParagraph from './EndorsementParagraph';
import EndorsementVideo from './EndorsementVideo';

const EndorsementSavedMoney = () => {
  return (
    <>
      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={12}
      >
        <Flex
          bg="#675395"
          w={{
            base: '100%',
            lg: '60%',
          }}
          flexDir="column"
          py={10}
          ps={10}
          pe={5}
          pos="relative"
          zIndex={1}
        >
          <Image src={EndorsementsLogo} w={220} pos="absolute" left={8} top="-24px" />
          <EndorsementHeading
            heading="they found the process simple, easy, fast & convenient"
            color="#F4C53E"
            textAlign="left"
            mt={16}
          />
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '40%',
          }}
        >
          <EndorsementVideo name="PurpleMoney" />
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column-reverse',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        mt={8}
      >
        <Flex
          justifyContent="center"
          w={{
            base: '100%',
            lg: '60%',
          }}
          mb={{
            base: '16px',
            lg: '48px',
          }}
          mt={{
            base: 0,
            lg: '-48px',
          }}
          pos="relative"
          zIndex={2}
        >
          <Box
            w={{
              base: '100%',
              lg: '75%',
            }}
            bg="#FFF"
            p={3}
            borderRadius={8}
            boxShadow="0 0 10px rgba(0,0,0,0.18)"
            mt={{
              base: 8,
              lg: 0,
            }}
          >
            <Text fontSize="13px" color="#676767" textTransform="uppercase" fontWeight={700}>
              Get started with your License Plate or VIN
            </Text>
            <Widget btnLabel="SKIP THE DEALERSHIP" />
          </Box>
        </Flex>
      </Flex>

      <Flex
        justifyContent="flex-start"
        flex={1}
        w="100%"
        gap={8}
        py={{
          base: 5,
          lg: 10,
        }}
        borderBottomWidth={1}
        borderTopWidth={1}
        flexDir={{
          base: 'column',
          md: 'row',
        }}
      >
        <Flex
          w={{
            base: '100%',
            md: '70%',
          }}
          flexDir="column"
          alignItems={{
            base: 'center',
            md: 'flex-start',
          }}
        >
          <EndorsementHeading
            color="#012d5d"
            heading="we partner with amazing banks"
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            lineHeight="72px"
          />
          <EndorsementParagraph
            fontWeight={500}
            pe={{
              base: 0,
              md: 32,
            }}
            mt={8}
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            paragraph="When it comes to leases, we've seen it all. Lease End has helped over 27,000 happy customers end their leases, all while avoiding dealership-induced headaches. Dodging surprise over-mileage fees? Yep, we've got that covered. Thought you couldn't qualify for a loan? Think again. We've worked magic there too. Basically if you're ready to end your lease we're the pros who make it possible."
          />
        </Flex>
        <Flex flex={1}>
          <Image src={EndorsementsBankLoop} w="100%" />
        </Flex>
      </Flex>

      <Flex
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flex={1}
        w="100%"
        bg="#F4C53E"
      >
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
        >
          <EndorsementVideo name="Andrea-BetterRate" />
          <Flex
            flex={1}
            justifyContent="center"
            mt={8}
            display={{
              base: 'none',
              lg: 'flex',
            }}
          >
            <Image src={EndorsementsNewVehicle} w="50%" />
          </Flex>
        </Flex>
        <Flex
          w={{
            base: '100%',
            lg: '50%',
          }}
          flexDir="column"
          p={8}
        >
          <EndorsementHeading
            heading="the most affordable car might be the one you're already driving"
            textAlign="left"
          />
          <EndorsementParagraph
            fontWeight={700}
            fontSize={24}
            paragraph="New car prices are up 10% since August of 2021"
            mt={4}
          />
          <EndorsementParagraph
            mt={8}
            fontWeight={500}
            fontSize={20}
            lineHeight={1.5}
            paragraph="Thanks to the equity you've built up, you might just snag your car for less than what it's worth on the market! When you first leased it, the manufacturer took a guess at your car's value at the end fo the lease, but guess what? Many used cars have held their value better than expected! That means the buyout price could be lower than what you'd find anywhere else. So if you're thinking about buying your car - or even flipping it for a profit - Lease End is here to help you navigate the whole process!"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default EndorsementSavedMoney;
