import { Button, ButtonGroup } from '@chakra-ui/react';
import React, { useState } from 'react';

type Props = {
  onChange: (val: string) => unknown;
  firstOption: string;
  secondOption: string;
};

const SwitchEndorsement = ({ onChange, firstOption, secondOption }: Props) => {
  const [selected, setSelected] = useState(firstOption);

  const handleChange = (val: string): void => {
    setSelected(val);
    onChange(val);
  };

  return (
    <ButtonGroup borderRadius="24px" bg="transparent" w="100%" mb={4}>
      <Button
        color={selected === firstOption ? 'black' : '#888'}
        _hover={{
          borderColor: '#F4C53E',
        }}
        h="32px"
        w="50%"
        borderRadius="4px 4px 0 0"
        borderBottomWidth="2px"
        borderBottomColor={selected === firstOption ? '#F4C53E' : '#ccc'}
        background="white"
        fontSize={18}
        fontWeight={selected === firstOption ? 700 : 500}
        onClick={() => handleChange(firstOption)}
      >
        {firstOption}
      </Button>
      <Button
        bg="transparent"
        color={selected === secondOption ? 'black' : '#888'}
        _hover={{
          borderColor: '#F4C53E',
        }}
        background="white"
        h="32px"
        w="50%"
        borderRadius="4px 4px 0 0"
        borderBottomWidth="2px"
        fontSize={18}
        fontWeight={selected === secondOption ? 700 : 500}
        borderBottomColor={selected === secondOption ? '#F4C53E' : '#ccc'}
        ml="0 !important"
        onClick={() => handleChange(secondOption)}
      >
        {secondOption}
      </Button>
    </ButtonGroup>
  );
};

export default SwitchEndorsement;
