import { Box, Flex, Img } from '@chakra-ui/react';
import Subtitle from '../../../components/Subtitle';
import {
  GreenHandKey,
  GreenTag,
  LeaseEndCarHauler,
  LeaseEndGuyHuggingCar,
  LeaseEndGuyGivingMoney,
} from '../../../assets/Images';

const CustomButton = ({
  onClick,
  title,
  color,
  active,
  picture,
  icon,
  isKeep,
}: {
  onClick: () => void;
  title: string;
  color: string;
  active?: boolean;
  picture: string;
  icon: string;
  isKeep: boolean;
}) => {
  return (
    <Box>
      <Flex
        as="button"
        width={{ base: !active ? '125px' : '200px', lg: !active ? '433px' : '500px' }}
        height={{ base: !active ? '89px' : '140px', lg: !active ? '110px' : '160px' }}
        bgColor={color}
        flexDirection="column"
        alignItems="center"
        padding={{ base: '18px', lg: '0 0 20px 0' }}
        margin="0 auto 20px"
        borderTopLeftRadius={{ base: 15, lg: isKeep ? (active ? 15 : 15) : active ? 15 : 0 }}
        borderTopRightRadius={{ base: 15, lg: isKeep ? (active ? 15 : 0) : active ? 15 : 15 }}
        transition="all 0.5s ease"
        marginBottom="20px"
        boxShadow={{
          lg: !active
            ? isKeep
              ? 'inset -5px -5px 10px 0 #cca434'
              : 'inset 5px -5px 10px 0 #c8751b'
            : undefined,
        }}
        _hover={{
          lg: {
            bgColor: { color },
            height: { base: '89px', lg: !active ? '139px' : '150px' },
            width: { base: '125px', lg: '500px' },
          },
        }}
        onClick={onClick}
      >
        <Img
          w={{ base: !active ? '30px' : '60px', lg: active ? '100px' : '80px' }}
          marginBottom="-10px"
          src={picture}
          marginTop={{ base: !active ? '-30px' : '-50px', lg: '-45px' }}
          transition="all 0.5s ease"
        />
        <Subtitle
          textColor={active ? 'black' : 'white'}
          marginTop={{ base: 3, lg: 6 }}
          fontSize={{ base: active ? 22 : 14, lg: active ? 46 : 34 }}
          fontWeight="light"
        >
          {title}
        </Subtitle>
        <Box>
          <Img
            position="absolute"
            marginTop={isKeep ? -5 : active ? 5 : 0}
            w={!isKeep ? (active ? '220px' : '150px') : active ? '180px' : '90px'}
            marginLeft={isKeep ? (active ? '-90px' : '-40px') : active ? '-100px' : '-60px'}
            src={icon}
            display={{ base: 'block', lg: 'none' }}
          />
          {!isKeep ? (
            <Img
              position="absolute"
              marginTop="-40px"
              marginLeft={active ? '-120px' : '-80px'}
              w={active ? '60px' : '40px'}
              src={LeaseEndGuyGivingMoney}
              display={{ base: 'block', lg: 'none' }}
            />
          ) : undefined}
        </Box>
      </Flex>
    </Box>
  );
};

type KeepOrSellButtonProps = {
  onSelected: (isKeepMyCarSelected: boolean) => void;
  isKeepMyCar: boolean;
};

const KeepOrSellButton = ({ onSelected, isKeepMyCar }: KeepOrSellButtonProps) => {
  const handleKeepButtonClick = () => {
    // inputRef?.current?.focus();
    onSelected(true);
  };
  const handleSellButtonClick = () => {
    // inputRef?.current?.focus();
    onSelected(false);
  };

  return (
    <Flex flexDirection="row" alignItems="end" justifyContent="center" gap={{ base: 5, lg: 0 }}>
      <CustomButton
        onClick={handleKeepButtonClick}
        color="maize"
        title="Keep My Car"
        active={isKeepMyCar}
        picture={GreenHandKey}
        icon={LeaseEndGuyHuggingCar}
        isKeep
      />
      <CustomButton
        onClick={handleSellButtonClick}
        color="carrotOrange"
        title="Sell My Car"
        active={!isKeepMyCar}
        picture={GreenTag}
        icon={LeaseEndCarHauler}
        isKeep={false}
      />
    </Flex>
  );
};

export default KeepOrSellButton;
