import { Avatar, AvatarProps, Box, Flex, Img, Text } from '@chakra-ui/react';

import { GreenArrow } from '../../../assets/Images';
import { LDFlags } from '../../../constants/experiments';
import useFlag from '../../../hooks/useFlag';

interface CircleStepProps extends AvatarProps {
  step: string | number;
  size: AvatarProps['size'];
  fontSize: AvatarProps['fontSize'];
}

export const CircleStep = ({ step, size, fontSize, ...rest }: CircleStepProps) => {
  return (
    <Avatar
      size={size}
      bg="#007DC5"
      color="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      icon={
        <Text fontSize={fontSize} fontFamily="sans-serif">
          {step}
        </Text>
      }
      {...rest}
    />
  );
};

const CustomStep = ({
  title,
  subtitle,
  step,
}: {
  title: string;
  subtitle: string;
  step: string;
}) => {
  return (
    <Flex maxW={{ base: '380px', lg: '280px' }} flexDir="column" justifyContent="space-between">
      <Flex alignItems="center" flexDir={{ base: 'row', lg: 'column' }} mb={5}>
        <CircleStep
          step={step}
          size={{ base: 'lg', lg: 'xl' }}
          fontSize={{ base: '40px', lg: '50px' }}
        />
        <Text
          minH={{ md: '60px' }}
          mt={{ base: 0, lg: 5 }}
          ml={{ base: 5, lg: 0 }}
          fontSize={20}
          textAlign={{ base: 'left', lg: 'center' }}
          fontWeight="semibold"
        >
          {title}
        </Text>
      </Flex>
      <Text fontSize="15px" textAlign="left">
        {subtitle}
      </Text>
    </Flex>
  );
};

type FourStepsProps = {
  isKeepMyCar: boolean;
};

const FourSteps = ({ isKeepMyCar }: FourStepsProps) => {
  const acquisitionsFlow = useFlag(LDFlags.ACQUISITIONS_FLOW);

  return (
    <Box
      mb={20}
      textAlign="center"
      marginTop={acquisitionsFlow ? '120px' : { md: 0, lg: '310px', xl: '240px' }}
      mx={1}
    >
      <Text
        textAlign="center"
        fontSize={{ base: 28, lg: 40 }}
        w={acquisitionsFlow && { base: '280px', lg: '100%' }}
        marginLeft={acquisitionsFlow && 10}
        fontWeight="normal"
      >
        {isKeepMyCar
          ? '4 easy steps to keeping your car'
          : 'End your car lease in just 4 easy steps'}
      </Text>
      <Flex
        marginTop={{ base: 5, lg: 10 }}
        alignItems="flex-start"
        flexDir={{ base: 'column', lg: 'row' }}
        gap={{ base: 6, lg: 5 }}
      >
        <CustomStep
          title="Tell us about your car"
          subtitle="We'll ask some easy questions about you & your lease to prepare for your purchase."
          step="1"
        />
        <Img marginTop="50px" w="50px" src={GreenArrow} display={{ base: 'none', lg: 'block' }} />
        <CustomStep
          title={isKeepMyCar ? 'View your loan & coverage options' : 'Snap some photos of your car'}
          subtitle={
            isKeepMyCar
              ? 'Review your unique financing & customizable vehicle coverage options.'
              : 'We’ll have you send us some pictures of your vehicle – no in-person inspection required. Once we’ve reviewed your photos and received approval to purchase your car, we’ll make you an offer.'
          }
          step="2"
        />
        <Img marginTop="50px" w="50px" src={GreenArrow} display={{ base: 'none', lg: 'block' }} />
        <CustomStep
          title="Sign your buyout documents"
          subtitle="We'll prepare the documents you need & you'll eSign securely on your Lease End account."
          step="3"
        />
        <Img marginTop="50px" w="50px" src={GreenArrow} display={{ base: 'none', lg: 'block' }} />
        <CustomStep
          title="Relax - we'll take it from there"
          subtitle={
            isKeepMyCar
              ? 'We’ll arrange titling, registration, & new plates for you so you can skip the DMV trip.'
              : `After we’ve reviewed your paperwork and processed your payoff, we’ll write you a check for the sale. Then, we’ll schedule a time to come to you and pick the car up (saving you a trip to the dealership).`
          }
          step="4"
        />
      </Flex>
    </Box>
  );
};

export default FourSteps;
