import { Box, Flex, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { FAQBanner } from '../../assets/Images';
import { faqFetch } from '../Sanity/Data';
import { FAQCategoryProps, FAQProps } from '../Sanity/Model';
import Item from './Item';

const Category = (props: { category: FAQCategoryProps }) => {
  const { category } = props;
  const [faqs, setFaqs] = useState<FAQProps[] | null>(null);
  const fetchFaq = async (faqCategory: string) => {
    const data: FAQProps[] | null = await faqFetch(faqCategory);
    if (data) {
      setFaqs(data);
    }
  };
  useEffect(() => {
    if (category) {
      fetchFaq(category.title);
    }
  }, [category]);
  return (
    <>
      <Box border="1px solid #ddd" mt={8} ps={8} pe={8} pb={2} borderRadius={16}>
        <Flex justifyContent="flex-start" direction="row" alignItems="center">
          <img
            src={FAQBanner}
            alt=""
            width={16}
            style={{
              marginRight: 8,
              marginTop: 4,
            }}
          />
          <Heading
            pt={6}
            pb={4}
            mt={0}
            as="h2"
            size="lg"
            fontWeight={600}
            lineHeight={1.3}
            color="leaseEndBlueDark"
          >
            {category.title}
          </Heading>
        </Flex>
        {faqs &&
          faqs.map((faq: FAQProps) => {
            return <Item faq={faq} />;
          })}
      </Box>
    </>
  );
};

export default Category;
