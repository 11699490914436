/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import {
  CareerHero,
  CareerLocations,
  LEValuesDD,
  LEValuesGSD,
  LEValuesLEAN,
  LEValuesP1,
  LEValuesUU,
} from '../../assets/Images';
import '../../assets/sanity.css';
import Title from '../../components/Title';
import Loading from '../Loading';
import { jobPostingFetch } from '../Sanity/Data';
import { JobPostingProps } from '../Sanity/Model';
import Job from './Job';

interface HeroProps {
  mobile?: boolean;
}

const Hero = ({ mobile }: HeroProps) => {
  return (
    <>
      <Title
        fontSize={mobile ? '20px' : '24px'}
        fontWeight={300}
        color={mobile ? 'leaseEndBlue' : 'white'}
        textAlign={mobile ? 'center' : 'left'}
        m={0}
        p={0}
      >
        Honestly? Car leases are kind of boring...
      </Title>
      <Title
        fontSize={{
          base: '28px',
          md: '32px',
          lg: '36px',
        }}
        fontWeight={800}
        color={mobile ? 'leaseEndBlue' : 'white'}
        textAlign={mobile ? 'center' : 'left'}
        m={0}
        p={0}
      >
        Working at Lease End isn't
      </Title>
    </>
  );
};

const Careers = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [jobs, setJobs] = useState<JobPostingProps[] | null>(null);
  const fetch = async () => {
    setLoading(true);
    const data: JobPostingProps[] | null = await jobPostingFetch();
    setLoading(false);
    setJobs(data);
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" px={{ md: 16 }}>
      <Box
        display={{
          base: 'block',
          md: 'none',
        }}
        mt={4}
        mb={6}
      >
        <Hero mobile />
      </Box>
      <Flex
        background="blue"
        justifyContent="center"
        flexDir="row"
        maxW="1920px"
        alignContent="center"
        mx="auto"
        pos="relative"
        mb={{
          base: 8,
          md: 16,
        }}
      >
        <Image src={CareerHero} w="100vw" />
        <Box
          display={{
            base: 'none',
            md: 'block',
          }}
          pos="absolute"
          backgroundColor="rgba(8,125,197,1)"
          left={0}
          flexDir="column"
          alignItems="flex-start"
          p={{
            base: 2,
            lg: 4,
          }}
          pe={{
            base: 16,
            lg: 24,
          }}
          borderBottomRightRadius={900}
        >
          <Hero />
        </Box>
      </Flex>
      <Flex
        maxW="container.xl"
        mx="auto"
        justifyContent="center"
        flexDir={{
          base: 'column',
          md: 'row',
        }}
        alignItems="flex-start"
        mt={{
          base: 0,
          md: 8,
        }}
        gap={{
          base: 8,
          md: 24,
        }}
      >
        <Flex
          w={{
            base: '100%',
            md: '50%',
          }}
          flexDir="column"
          flex={1}
        >
          <Title fontSize="24px" textAlign="left" fontWeight={700}>
            Who are we?
          </Title>
          <Text textAlign="left" fontSize="16px">
            We are a group of people who believe there is a better way to end your lease. We work
            hard to provide the best way for people to end their lease on their terms. We are
            disrupting the status-quo of the car lease cycle.{' '}
          </Text>
          <Box borderWidth={1} p={2} borderRadius={8} mt={4}>
            <video
              autoPlay={false}
              controls
              muted={false}
              loop={false}
              style={{
                width: '100%',
              }}
            >
              <source src="/videos/careers-who-are-we.mp4" type="video/mp4" />
            </video>
          </Box>
        </Flex>
        <Flex
          w={{
            base: '100%',
            md: '50%',
          }}
          flexDir="column"
          borderWidth={1}
          px={8}
          py={4}
          borderRadius={8}
          backgroundColor="#fafafa"
        >
          <Title fontSize="24px" textAlign="left" fontWeight={700}>
            Current Openings
          </Title>
          {loading ? (
            <Loading />
          ) : jobs && jobs.length ? (
            <>
              <Flex flexWrap="wrap" gap={0} flexDir="column">
                {jobs.map((posting: JobPostingProps) => {
                  return <Job posting={posting} key={posting.name} />;
                })}
              </Flex>
            </>
          ) : (
            <>Something went wrong</>
          )}
          <Box borderTopWidth={1} py={4} mt={4}>
            <Text fontSize="14px" color="#888" fontWeight={400}>
              Interested in working for Lease End but not seeing a position for you? You can always
              email us your resume at <Link>hr@leaseend.com</Link>!
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex
        maxW="container.lg"
        justifyContent="center"
        flexDir={{
          base: 'column',
          md: 'row',
        }}
        alignContent="center"
        mx="auto"
        gap={{
          base: 8,
          md: 24,
        }}
        my={12}
        borderBottomWidth={1}
        borderTopWidth={1}
        py={12}
      >
        <Flex
          w={{
            base: '80%',
            md: '40%',
          }}
          flex={1}
          alignItems="flex-start"
          justifyContent={{
            base: 'center',
            md: 'flex-start',
          }}
          mx="auto"
        >
          <Image src={CareerLocations} w="100%" />
        </Flex>
        <Flex
          w={{
            base: '100%',
            md: '60%',
          }}
          gap={2}
          flexDir="column"
        >
          <Text
            fontSize="16px"
            textAlign={{
              base: 'center',
              md: 'left',
            }}
          >
            Our team of 130+ (and growing quickly) is split between 3 offices. One in Lehi, Utah,
            another in Twin Falls, Idaho and our headquarters in Burley, Idaho.{' '}
          </Text>
          <Text
            py={{
              base: 4,
              md: 8,
            }}
            my={4}
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            fontSize={{
              base: '28px',
              md: '36px',
            }}
            fontWeight={200}
            lineHeight={{ base: '36px', md: '44px' }}
          >
            "I really do love working for Lease End and I've never had a case of the "Sunday
            Scaries!"{' '}
          </Text>
          <Flex
            flexDir="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            ms={{
              base: '50%',
              md: '70%',
            }}
          >
            <Text fontSize="20px" fontWeight={600}>
              Chris Traeger
            </Text>
            <Text fontWeight="300" fontSize="16px">
              Advisor
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        maxW="container.xl"
        justifyContent="center"
        flexDir="row"
        alignContent="center"
        mx="auto"
        flexWrap="wrap"
        gap={4}
        my={16}
      >
        <Flex flex={1}>
          <Image src={LEValuesDD} w="120px" />
        </Flex>
        <Flex flex={1}>
          <Image src={LEValuesGSD} w="120px" />
        </Flex>
        <Flex flex={1}>
          <Image src={LEValuesP1} w="120px" />
        </Flex>
        <Flex flex={1}>
          <Image src={LEValuesLEAN} w="90px" />
        </Flex>
        <Flex flex={1}>
          <Image src={LEValuesUU} h="110px" />
        </Flex>
      </Flex>
    </Container>
  );
};

export default Careers;
