import { Flex, Link, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { BlogPostProps } from '../Sanity/Model';

interface AuthorProps {
  post: BlogPostProps;
  showDate?: boolean;
  link?: boolean;
}

const Author = ({ link, post, showDate }: AuthorProps) => {
  const location = useLocation();
  return (
    <Flex alignItems="flex-start" gap={2} flexDir="row" flex={1} w="100%" mb={3}>
      {post.authorImage && <BlogImageComponent value={post.authorImage} isInline isAuthor />}
      <Flex flexDir="column" alignItems="flex-start" gap={0}>
        {link ? (
          <Link
            className="le-blog-author"
            fontWeight={700}
            fontSize="12pt"
            href={`/author/${post.authorSlug}?ref=${location.pathname}`}
          >
            {post.authorName}
          </Link>
        ) : (
          <Text fontWeight={700} fontSize="12pt" className="le-blog-author" color="#222">
            {post.authorName}
          </Text>
        )}
        {showDate && (
          <Text className="le-blog-date" color="#545454" fontSize="10pt">
            {moment(new Date(post.publishDate)).format('M/D/YY h:mma')}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default Author;
