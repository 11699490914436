import { Center, Container, Flex } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import { useEffect, useState } from 'react';

import BtnGetStarted from '../../components/BtnGetStarted';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import GetStarted from '../../components/ui/GetStarted/GetStarted';
import { faqCategoryFetch, pageFetch } from '../Sanity/Data';
import { FAQCategoryProps, SanityPageProps, ptBaseComponents } from '../Sanity/Model';
import Category from './Category';

const Faq = () => {
  const [page, setPage] = useState<SanityPageProps | null>(null);
  const [categories, setCategories] = useState<FAQCategoryProps[] | null>(null);

  const fetch = async () => {
    const data: SanityPageProps[] | null = await pageFetch('FAQs');
    if (data) {
      setPage(data[0]);
    }
  };

  const fetchCategories = async () => {
    const data: FAQCategoryProps[] | null = await faqCategoryFetch();
    if (data) {
      setCategories(data);
    }
  };

  useEffect(() => {
    fetch();
    fetchCategories();
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Title fontSize={{ base: '36px', md: '52px' }}>
          You've got questions? <br />
          We've got answers.
        </Title>
      </TitleContainer>
      <Subtitle textAlign="center">{page?.subtitle}</Subtitle>
      {page?.body && <PortableText value={page?.body} components={ptBaseComponents} />}
      <Flex justifyContent="center">
        <BtnGetStarted mt={4} />
      </Flex>
      {categories &&
        categories.map((category: FAQCategoryProps) => {
          return <Category category={category} />;
        })}
      {page?.widget && (
        <Center my={16}>
          <GetStarted />
        </Center>
      )}
    </Container>
  );
};

export default Faq;
