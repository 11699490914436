/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable no-undef */
import { Image } from '@chakra-ui/react';
import { useState } from 'react';

interface EndorsementVideoProps {
  name: string;
}

const EndorsementVideo = ({ name }: EndorsementVideoProps) => {
  const [playing, setPlaying] = useState<boolean>(false);
  return (
    <>
      {playing ? (
        <video
          autoPlay
          controls
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          style={{
            background: 'white',
          }}
        >
          <source src={`/videos/endorsements/${name}.mp4`} type="video/mp4" />
        </video>
      ) : (
        <Image
          src={`/leaseend-com-images/endorsements/${name}.png`}
          onClick={() => setPlaying(true)}
        />
      )}
    </>
  );
};

export default EndorsementVideo;
