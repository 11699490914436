import { Flex, FlexProps, Text, useMediaQuery } from '@chakra-ui/react';

import Title from '../../../components/Title';
import GetStarted from '../../../components/ui/GetStarted/GetStarted';
import { LDFlags } from '../../../constants/experiments';
import { useFlag } from '../../../hooks';
import useCheckFlowStatus from '../../../hooks/useCheckFlowStatus';
import useCustomBreakpointCallback from '../../../hooks/useCustomBreakpointCallback';
import useHomePageVariations from '../../../hooks/useHomePageVariations';

const HomeHero = ({ ...props }: FlexProps) => {
  const { inFlow } = useCheckFlowStatus();
  const bpv = useCustomBreakpointCallback();
  const [heightUnder1050] = useMediaQuery('(max-height: 1050px)');
  const homePageBannerAd = useFlag(LDFlags.HOME_PAGE_VALENTINES_BANNER);
  const homePageVariation = useHomePageVariations();
  return (
    <Flex
      flexDir="column"
      textAlign="center"
      alignItems="center"
      paddingBottom="20px"
      transform={{
        base: 'scale(0.9)',
        md: bpv(
          {
            600: 'scale(0.8)',
            800: inFlow ? 'scale(0.85)' : 'scale(0.9)',
            900: inFlow ? 'scale(0.9)' : 'scale(1)',
            1000: 'scale(1)',
          },
          true,
        ),
      }}
      mt={{
        base: inFlow ? '-120px' : '-30px',
        sm: bpv(
          {
            700: inFlow ? '-80px' : '-50px',
            800: inFlow ? '-40px' : '-30px',
            1000: '-50px',
          },
          true,
        ),
        md: bpv(
          {
            700: inFlow ? '-80px' : '-50px',
            800: inFlow ? '-40px' : '-30px',
            1000: '-50px',
          },
          true,
        ),
      }}
      {...props}
    >
      {inFlow ? (
        <>
          <Title
            fontWeight={700}
            pt={{ base: '120px', md: heightUnder1050 ? '100px' : '125px' }}
            mb={{ base: '60px', md: '110px' }}
            size={{ base: '3xl', lg: '3xl' }}
            color="black"
          >
            End your lease, <Text as="br" display={{ md: 'none' }} />
            keep your car.
          </Title>
        </>
      ) : (
        <>
          <Title
            fontWeight={700}
            pt={bpv(
              {
                0: '10px',
                875: '45px',
                960: '75px',
                1050: '125px',
              },
              true,
            )}
            size={{ base: '3xl', lg: '3xl' }}
            color="black"
          >
            {homePageBannerAd ? 'Love Your Leased Car?' : homePageVariation.homeHero.title}
          </Title>
          <Text
            fontSize={{ base: 'xl', lg: '2xl' }}
            mb="25px"
            display={{ base: 'none', sm: 'block' }}
          >
            {homePageVariation.homeHero.copy}
          </Text>
        </>
      )}
      <GetStarted />
    </Flex>
  );
};

export default HomeHero;
