/* eslint-disable react/jsx-no-undef */

/* eslint-disable react/jsx-pascal-case */
import { Box, Button, Flex, Image, Link, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PrequalOrnament } from '../../assets/Images';
import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import Title from '../../components/Title';
import { COMErrorCodes } from '../../constants/errors';
import { LDFlags } from '../../constants/experiments';
import { QueryParams } from '../../constants/queryParam';
import { BUY_YOUR_LEASED_CAR, LP_PREQUAL } from '../../constants/urls';
import useFlag from '../../hooks/useFlag';
import useRefSize from '../../hooks/useRefSize';
import { createErrorToast } from '../../utils/toast';
import CarDudeBgImage from './components/CarDudeBgImage';
import ExpiredPayoffRequestModal from './components/ExpiredPayoffRequestModal';
import FaqAndArticles from './components/FaqAndArticles';
import HomeHero from './components/HomeHero';
import HomePageBannerAd from './components/HomePageBannerAd';
import HowCanWeHelp from './components/HowCanWeHelp';
import MidPageBanner from './components/MidPageBanner';
import MonthlyPayment from './components/MonthlyPayment';
import ReadyToBuyYourLeasedCar from './components/ReadyToBuyYourLeasedCar';
import ReviewLinks from './components/ReviewLinks';
import Reviews from './components/Reviews';
import Steps from './components/Steps';
import TellMeMoreAboutLeasedBuyouts from './components/TellMeMoreAboutLeasedBuyouts';
import WhyBuyYourLeasedCar from './components/WhyBuyYourLeasedCar';

const Home = () => {
  const homePageBannerAd = useFlag(LDFlags.HOME_PAGE_VALENTINES_BANNER);
  const LP_FLAG_PREQUAL = useFlag(LDFlags.LP_PREQUAL);
  const { pathname } = useLocation();
  const [expiredPayoffRequestModalOpen, setExpiredPayoffRequestModalOpen] = useState(false);
  const [imgRef, { height }] = useRefSize<HTMLImageElement>();
  const {
    replace,
    location: { search },
  } = useHistory();
  const toast = useToast();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const errCode = params.get(QueryParams.ERR_CODE);
    if (errCode === COMErrorCodes.NoDealFound) {
      params.delete(QueryParams.ERR_CODE);
      replace({ search: params.toString() });
      toast(
        createErrorToast({
          description:
            "We weren't able to find any information associated with the email you " +
            'provided. Please enter your License Plate or VIN to get started. If you think this ' +
            'is an error, please get in touch with our support team for help.',
        }),
      );
    }
  }, [search]);

  return (
    <>
      <Box h="100%">
        <Box>
          <Nav />
        </Box>
        <CarDudeBgImage ref={imgRef} />
        <HomeHero h={height ? height - height * 0.165 : 910} />
        {homePageBannerAd && <HomePageBannerAd />}
        {LP_FLAG_PREQUAL && (
          <Flex
            justifyContent="center"
            borderWidth={1}
            borderRadius={8}
            mx="auto"
            mb={8}
            maxW="500px"
            p={5}
            bg="#f7f7f7"
            flexDir="column"
            flex={1}
            background="linear-gradient(180deg, rgba(186,244,255,1) 0%, rgba(186,244,255,0.5) 5%, rgba(255,255,255,1) 50%, rgba(186,244,255,0.5) 95%, rgba(186,244,255,1) 100%)"
          >
            <Flex
              alignItems="flex-start"
              justifyContent="space-between"
              pos="relative"
              flex={1}
              flexDir="column"
              gap={4}
            >
              <Title
                textAlign="left"
                color="#000"
                fontSize={{
                  base: 24,
                  md: 32,
                }}
                fontWeight={800}
                me={48}
              >
                Get prequalified
                <Text as="br" /> with{' '}
                <Text as="span" textDecoration="underline">
                  no hit
                </Text>{' '}
                to
                <Text as="br" />
                your credit !
              </Title>
              <Text fontSize={16} fontWeight={500}>
                In just 1 minute we'll get you prequalified for a loan to end your lease and keep
                your car
              </Text>
              <Image pos="absolute" src={PrequalOrnament} w={300} right="-120px" top="-32px" />
            </Flex>
            <Flex flex={1} mt={4}>
              <Link href={LP_PREQUAL} w="100%">
                <Button
                  bg="#52B47F"
                  color="white"
                  letterSpacing={0}
                  fontSize={18}
                  py={8}
                  w="100%"
                  _hover={{
                    background: '#38a76b',
                  }}
                  mt={2}
                >
                  Get Prequalified Now
                </Button>
              </Link>
            </Flex>
          </Flex>
        )}
        <Steps />
        <ReviewLinks />
        <HowCanWeHelp />
        {pathname === BUY_YOUR_LEASED_CAR && (
          <>
            <WhyBuyYourLeasedCar />
            <TellMeMoreAboutLeasedBuyouts />
            <ReadyToBuyYourLeasedCar />
            <MonthlyPayment />
          </>
        )}
        <MidPageBanner />
        <Reviews />
        {/* {pathname !== BUY_YOUR_LEASED_CAR && <MonthlyPayment />} */}
        <FaqAndArticles />
        <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
      </Box>
      <ExpiredPayoffRequestModal
        isOpen={expiredPayoffRequestModalOpen}
        onClose={() => setExpiredPayoffRequestModalOpen(false)}
      />
    </>
  );
};

export default Home;
