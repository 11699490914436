import {
  Box,
  BoxProps,
  Collapse,
  Container,
  Flex,
  Image,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AboutUsBrandonWilliams,
  AboutUsChipCook,
  AboutUsDaveWilliams,
  AboutUsJeremyToner,
  AboutUsLeaseOwner,
  AboutUsZanderCook,
  AllyBankLogo,
  CapitalOneBankLogo,
  FirstInterstateBankLogo,
  MacuBankLogo,
  TdBankLogo,
} from '../assets/Images';
import Description from '../components/Description';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import TitleContainer from '../components/TitleContainer';
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import { LDFlags } from '../constants/experiments';
import { HOW_IT_WORKS } from '../constants/urls';
import useFlag from '../hooks/useFlag';

interface TeamMemberProps extends BoxProps {
  image: string;
  name: string;
  title: string;
  car: string;
  carDescription: string;
  bio: JSX.Element;
}

const TeamMember = ({ image, name, title, car, carDescription, bio, ...rest }: TeamMemberProps) => {
  const [showBio, setShowBio] = useState(false);
  const handleClick = () => {
    setShowBio(!showBio);
  };

  return (
    <Box
      cursor="pointer"
      p={{ base: 2, md: 6 }}
      color="black"
      transition="0.3s ease-out"
      _hover={{ bgColor: '#efefef' }}
      onClick={handleClick}
      {...rest}
    >
      <Flex mb="10px" minH="150px" flexDir={{ base: 'column', md: 'row' }}>
        <Image maxW="280px" w={{ md: '35%' }} margin="auto" src={image} alt={name} />
        <Box
          textAlign={{ base: 'center', md: 'left' }}
          fontSize="14px"
          lineHeight="24px"
          m={{ md: 'auto' }}
          pt="10px"
          pl="15px"
          w={{ md: '65%' }}
        >
          <Text fontSize="20px" color="royalblue">
            {name}
          </Text>
          {title}
          <br />
          <b>IF I WAS A CAR:</b> <i>{car}</i>
          <br />
          {carDescription}
        </Box>
      </Flex>
      <Collapse in={showBio}>
        <Text textAlign="left">{bio}</Text>
      </Collapse>
    </Box>
  );
};

const AboutUs = () => {
  const history = useHistory();

  const acquisitionsFlow = useFlag(LDFlags.ACQUISITIONS_FLOW);

  return (
    <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" px={{ md: 16 }}>
      <TitleContainer>
        <Title fontSize={{ base: '36px', md: '52px' }}>Tech to empower auto lease owners.</Title>
      </TitleContainer>
      <Subtitle as="h2" fontSize={{ base: '28px', md: '32px' }} mt="-10px">
        That's what we're here to create.
      </Subtitle>
      <Image mx="auto" mt="25px" w="525px" maxW="100%" src={AboutUsLeaseOwner} alt="Lease Owner" />
      <Box mt={{ base: '40px', lg: '20px' }} p={{ md: '0 75px 0', lg: '0 100px 0' }}>
        <Description color="leaseEndBlue" textAlign="left" fontWeight="500">
          When finalizing lease-end plans, car lease owners need someone with their best interests
          in mind—<b>someone they can trust.</b> Maybe, say, a company with an A+ rating from the
          Better Business Bureau® that believes in providing all car lease owners with decisive
          insights about their lease-end choices. (Hint: it’s us.)
          <br />
          <br />
          Access to comprehensive, user-friendly resources about lease-end options empowers car
          lease owners to make the decisions that are truly best for them.{' '}
          <b>This is what we're all about.</b>
          {acquisitionsFlow &&
            ` So we built an easier, faster, more convenient way to
          purchase, sell, or turn their leased vehicles in.`}
        </Description>
      </Box>

      <Spacer h={{ base: 50, md: 100 }} />
      <Text fontSize="32px" mb="30px" color="leaseEndBlue">
        Our Partners
      </Text>
      <Description color="leaseEndBlue">
        We’ve partnered with some of the best lenders out there to get you the
        <br />
        best rate on your auto loan.
      </Description>
      <Flex flexWrap="wrap" justifyContent="center" alignItems="center" mt="40px" w="100%">
        <Box w={{ base: '100%', md: '33%' }} p={6}>
          <Image m="auto" h="60px" src={AllyBankLogo} alt="Ally Bank" />
        </Box>
        <Box w={{ base: '100%', md: '33%' }} p={6}>
          <Image m="auto" h="60px" src={CapitalOneBankLogo} alt="Capital One" />
        </Box>
        <Box w={{ base: '100%', md: '33%' }} p={6}>
          <Image m="auto" h="55px" src={FirstInterstateBankLogo} alt="First Interstate Bank" />
        </Box>
        <Box w={{ base: '100%', md: '50%' }} mt={{ base: 0, md: 8 }} p={6}>
          <Image m="auto" mr={{ md: 8 }} h="150px" src={TdBankLogo} alt="TD Bank" />
        </Box>
        <Box w={{ base: '100%', md: '50%' }} mt={{ base: 0, md: 8 }} p={6}>
          <Image
            m="auto"
            ml={{ md: 8 }}
            h="100px"
            src={MacuBankLogo}
            alt="Mountain America Credit Union"
          />
        </Box>
      </Flex>

      <Spacer h={{ base: 50, md: 100 }} />
      <Text fontSize="32px" mb="30px" color="leaseEndBlue">
        Why do we do it?
      </Text>
      <Box mt={{ base: '40px', lg: '20px' }} p={{ md: '0 75px 0', lg: '0 100px 0' }}>
        <Description color="leaseEndBlue" textAlign="left" fontWeight="500">
          Brandon and Zander, our founders, both have backgrounds in the auto industry.
          <br />
          <br />
          They’ve had an up-close-and-personal view of just how painful the lease-end experience can
          be (and how dealerships capitalize on general misinformation about lease-end options).
          <br />
          <br />
          Even now, at Lease End, we’ve seen how un-consumer-friendly manufacturers can be and just
          how tightly they’ve tried to hold on to customers’ equity in their leased cars. This is
          further confirmation to us that <b>we’re on the right track.</b> We’re experiencing the
          same pushback that customers do, and if manufacturers feel comfortable trying to push us
          around, imagine what they’re trying to do to consumers.
          <br />
          <br />
          Our mission is to simplify the lease-end process and to keep clients from getting duped by
          their dealership. In short, we’re creating a <b>
            better experience for lease owners
          </b>{' '}
          that’s more intuitive to a modern, informed consumer. We’re putting car lease owners back
          in control and empowering them with the technology to easily exit their lease,{' '}
          <b>on their terms.</b>
        </Description>
      </Box>

      <Spacer h={{ base: 50, md: 100 }} />
      <Text fontSize="32px" mb="30px" color="leaseEndBlue">
        Our Leadership
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        <TeamMember
          borderBottom="1px solid #F0F0F0"
          borderRight={{ md: '1px solid #F0F0F0' }}
          image={AboutUsBrandonWilliams}
          name="Brandon Williams"
          title="CEO"
          car="Ford Galaxy 500"
          carDescription="Sleek, unapologetic & resilient"
          bio={
            <>
              Brandon got his B.S. in business at Utah Valley University. He’s been running
              full-steam as an entrepreneur ever since, investing in people to improve the world
              through leadership, products, and services. He has over 13 years of experience as an
              executive working in transportation logistics, the auto industry, ecommerce, and (now)
              Lease End. He is best known for founding and selling Doba, a fast-growing software
              company connecting small businesses through a virtual inventory fulfillment system to
              simplify the supply chain.
              <br />
              <br />
              In a small Idaho town, he conceptualized and built Lease End in February 2018,
              intending from the start for it to become a nationwide business. Like most startup
              founders, Brandon’s had a hand in every role, title, and responsibility at Lease End
              as it’s grown from a fledgling concept to a viable company.
            </>
          }
        />
        <TeamMember
          borderBottom="1px solid #F0F0F0"
          image={AboutUsZanderCook}
          name="Zander Cook"
          title="CRO"
          car="Ford F-150 Lightning"
          carDescription="Imaginative, cutting-edge & auspicious"
          bio={
            <>
              To make effective changes to the auto industry, we need people with fresh blood and
              out-of-the-box ideas to pioneer our vision. We need a mixed team with people who
              aren’t content with the way things have always been done at dealerships. Enter
              co-founder Zander Cook, who brings the innovative and strategic mindset required in
              this type of venture to our table.
              <br />
              <br />
              After earning his degree in economics at BYU in 2019, Zander worked finance at a car
              dealership - arranging loans, preparing documentation, the works - so he saw firsthand
              how leases were handled there. After a while, he noticed that it felt like no one at
              the dealership really “got” car leasing. So, he started thinking about leased cars as
              assets and brainstorming ideas to make the leasing experience better for consumers.
              Thus, Lease End was born. As Lease End continues to grow, Zander sincerely appreciates
              the opportunity he has to help people overcome the huge pain point of end-of-lease
              anxiety.
            </>
          }
        />
        <TeamMember
          borderBottom="1px solid #F0F0F0"
          borderRight={{ md: '1px solid #F0F0F0' }}
          image={AboutUsDaveWilliams}
          name="Dave Williams"
          title="CTO"
          car="Ford Bronco"
          carDescription="Distinguished, boundless & versatile"
          bio={
            <>
              Dave has over 20 years of experience in fintech. He earned his B.S. in economics from
              West Point (later serving as an engineer officer in the Army), got his MBA from the
              University of Hawaii, and has been a CFA since 1999. In his time in the fintech world,
              Dave has built asset securitization software, served as MD of a global sales team and
              President and COO of FinancialGuard (a robo-advisor), and founded his own fintech
              startup to create a personal economics modeling tool.
              <br />
              <br />
              Dave started as a tech advisor to Lease End in February 2021, and came on full-time
              later that year in December. As CTO, he loves that he gets to work with a talented
              group of makers to organize bits and bytes into real, valuable products that bring
              revolutionary transparency to lease-end transactions. He loves contributing to a
              vision of the future where people are empowered in directly controlling their assets.
            </>
          }
        />
        <TeamMember
          borderBottom="1px solid #F0F0F0"
          image={AboutUsJeremyToner}
          name="Jeremy Toner"
          title="CFO"
          car="Tesla Model 3"
          carDescription="Fast, modern & cool"
          bio={
            <>
              Jeremy joined Lease End in February 2022 as Chief Financial Officer. Prior to Lease
              End, Jeremy served 13 years as Director of Corporate Development at Midmark
              Corporation, a growing mid-sized MedTech company. While there, he led Mergers &
              Acquisitions and other growth initiatives. Under his leadership, the company more than
              doubled in size.
              <br />
              <br />
              Before Midmark, Jeremy spent 11 years in a variety of finance, M&A, and treasury
              positions at Procter & Gamble and served as CFO for several major household brands. He
              also spent time in P&G treasury, where he led the hedging of P&G’s debt portfolio.
              Between Midmark and P&G, Jeremy has led 16 successful acquisitions and divestitures.
              He received his degree in Economics from Stanford University with additional studies
              in German and Human Biology.
            </>
          }
        />
        <TeamMember
          image={AboutUsChipCook}
          borderRight={{ md: '1px solid #F0F0F0' }}
          name="Chip Cook"
          title="COO"
          car="Audi A6"
          carDescription="Performance, sophistication & versatility"
          bio={
            <>
              Chip earned his BS from West Point, and went on to serve a career in the US Army. He
              also went to Stanford University to earn a master’s degree, and most recently earned
              his MBA at BYU. Chip has experience in strategic operational and logistic planning,
              supply chain management, and in mentoring leaders and developing high-functioning
              teams.
              <br />
              <br />
              Before joining Lease End, Chip spent a couple years with Amazon Logistics and 21 years
              in military leadership roles, from Brigade Operations Officer (the Army’s version of a
              COO) to Sr. Director for Talent Development. During his military service he deployed
              to both Iraq and Afghanistan with the storied 82nd Airborne Division. When he’s not
              busy jumping out of airplanes or telling people what to do, Chip loves staying active
              with CrossFit, skiing, and enjoying the outdoors with his family.
            </>
          }
        />
      </SimpleGrid>
      <Spacer h={{ base: 50, md: 100 }} />
      <Text fontSize="32px" mb="25px" color="leaseEndBlue">
        Want to learn more about how the lease-end process works?
      </Text>
      <PrimaryButton onClick={() => history.push(HOW_IT_WORKS)}>CLICK HERE</PrimaryButton>
      <Spacer h={{ base: 50, md: 100 }} />
    </Container>
  );
};

export default AboutUs;
