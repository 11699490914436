import { Link } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { BLOG_SANITY } from '../../constants/urls';

/* eslint-disable react/destructuring-assignment */

interface TagLinkProps {
  tag: string;
}

const TagLink = (props: TagLinkProps) => {
  const params: {
    tag: string;
  } = useParams();
  return (
    <Link
      p={1}
      px={2}
      key={props.tag}
      href={`${BLOG_SANITY}/tag/${props.tag}`}
      style={{
        fontSize: 12,
        fontWeight: params.tag === props.tag ? 700 : 500,
        backgroundColor: props.tag === params.tag ? '#002d5d' : '#eee',
        color: props.tag === params.tag ? 'white' : params.tag ? '#888' : '#454545',
        borderRadius: 4,
      }}
      _hover={{
        backgroundColor: props.tag === params.tag ? '#002d5d' : 'rgba(255, 255, 255, 0.18)',
      }}
    >
      {props.tag}
    </Link>
  );
};

export default TagLink;
