/* eslint-disable no-param-reassign */
import { Box, Flex, Input, Link, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import Title from '../../components/Title';
import Loading from '../Loading';
import { salesFetch, salesTeamFetch } from '../Sanity/Data';
import { SalesProps } from '../Sanity/Model';
import SalesTeam from './SalesTeam';
import SalesTeamMember from './SalesTeamMember';

const SalesTeamMain = () => {
  const params = new URLSearchParams(window.location.search);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<string>('');
  const [sales, setSales] = useState<SalesProps | null>(null);
  const [salesTeam, setSalesTeam] = useState<SalesProps[] | null>(null);
  const [showingSales, setShowingSales] = useState<number | undefined>(0);
  const fetch = async (name?: string | null) => {
    setLoading(true);
    if (params.get('name') && params.get('name') !== '') {
      const data: SalesProps | null = await salesFetch(name || '');
      setLoading(false);
      setSales(data);
      return;
    }
    const data: SalesProps[] | null = await salesTeamFetch();
    setLoading(false);
    setSalesTeam(data);
  };

  const handleFilter = (search: string, reset?: boolean) => {
    setSearching(reset ? '' : search);
    if (reset && formRef) {
      formRef.current?.reset();
      searchRef.current?.focus();
    }
    if (salesTeam) {
      const t: SalesProps[] = salesTeam.filter((team) => {
        const tm = team;
        if (
          tm.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
          search === '' ||
          reset
        ) {
          tm.hide = false;
        } else {
          tm.hide = true;
        }
        return tm;
      });
      setSalesTeam(t);
    }
  };

  const countSalesTeam = () => {
    setShowingSales(salesTeam?.filter((team) => team.hide !== true).length);
  };

  useEffect(() => {
    fetch(params.get('name'));
  }, [params.get('name')]);

  useEffect(() => {
    countSalesTeam();
  }, [salesTeam]);

  return (
    <>
      <Box pb={24}>
        {loading ? (
          <Loading />
        ) : sales && params.get('name') ? (
          <>
            <SalesTeamMember member={sales} />
          </>
        ) : (salesTeam && salesTeam.length) || searching ? (
          <>
            <Flex
              px={4}
              justifyContent="space-between"
              borderBottomWidth={1}
              alignContent="center"
              alignItems="center"
              pb={6}
              my={6}
              flexDir={{
                base: 'column',
                lg: 'row',
              }}
            >
              <Box
                w={{
                  base: '100%',
                  lg: '70%',
                }}
              >
                <Title
                  fontSize={{ base: '36px', md: '52px' }}
                  textAlign={{
                    base: 'center',
                    lg: 'left',
                  }}
                >
                  Your lease ending experts
                </Title>
              </Box>
              <Box
                mt={{ base: 4, lg: 0 }}
                w={{
                  base: '100%',
                  lg: '30%',
                }}
              >
                <form ref={formRef}>
                  <Input
                    ref={searchRef}
                    onChange={(e) => handleFilter(e.target.value)}
                    placeholder="Search for team member..."
                    borderRadius={32}
                    fontSize={20}
                    p={3}
                    px={8}
                    h="auto"
                  />
                </form>
              </Box>
            </Flex>
            <Flex flexWrap="wrap" gap={0}>
              {showingSales === 0 ? (
                <>
                  <Text fontSize={16}>
                    No team member found matching <strong>"{searching}"</strong>.
                  </Text>{' '}
                  <Link fontSize={16} ps={2} onClick={() => handleFilter('', true)}>
                    Clear search
                  </Link>
                </>
              ) : (
                salesTeam &&
                salesTeam.map((team: SalesProps) => {
                  return <SalesTeam sales={team} key={team.name} />;
                })
              )}
            </Flex>
          </>
        ) : (
          <>Something went wrong</>
        )}
      </Box>
    </>
  );
};

export default SalesTeamMain;
