import { Box, Divider, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import Subtitle from '../../components/Subtitle';
import Step from './Step';

const Aside = () => {
  return (
    <Flex minW="220px" flexDirection={{ sm: 'column', lg: 'row' }}>
      <Divider
        h="100%"
        bg="gray"
        mx={4}
        orientation="vertical"
        pl="0.5px"
        display={{ base: 'none', lg: 'block' }}
      />
      <Divider
        w="100%"
        bg="gray"
        my={4}
        orientation="horizontal"
        pt="0.5px"
        display={{ base: 'block', lg: 'none' }}
      />
      <Flex flexDirection="column" px={{ base: 6, md: 0 }}>
        <Subtitle fontSize={20} fontWeight="semibold">
          End Your Lease in 4 Easy Steps!
        </Subtitle>
        <Step fontColor="leaseEndBlue" stepNumber={1} title="Tell us about your car">
          We'll ask some easy questions about you & your lease to prepare for your purchase.
        </Step>
        <Step stepNumber={2} fontColor="leaseEndBlue" title="View your loan & coverage options">
          Review your unique financing & customizable vehicle coverage options.
        </Step>
        <Step stepNumber={3} fontColor="leaseEndBlue" title="Sign your buyout documents">
          We'll prepare the documents you need & you'll eSign securely on your Lease End account.
        </Step>
        <Step stepNumber={4} fontColor="leaseEndBlue" title="Relax - we'll take it from there">
          We'll arrange titling, registration, & new plates for you so you can skip the DMV trip.
        </Step>
        <Divider w="100%" bg="gray" my={4} pt="0.5px" />
        <Box color="leaseEndBlue">
          <Subtitle fontSize={20} fontWeight="semibold">
            Why choose Lease End?
          </Subtitle>
          <UnorderedList ml={8}>
            <ListItem>Expert tools & technology</ListItem>
            <ListItem>Professional advisory services</ListItem>
            <ListItem>No paperwork at the DMV or dealership!</ListItem>
            <ListItem>No hidden dings, dents or mileage fees!</ListItem>
          </UnorderedList>
        </Box>
        <Divider w="100%" bg="gray" my={4} pt="0.5px" />
        <Box color="leaseEndBlue">
          <Subtitle fontSize={20} fontWeight="semibold">
            Tech to empower lease owners
          </Subtitle>
          <Text>
            When finalizing lease-end plans, car lease owners need someone with their best interests
            in mind—someone they can trust. Maybe, say, a company with an A+ rating from the Better
            Business Bureau® that believes in providing all car lease owners with decisive insights
            about their lease-end choices. (Hint: it's us.)
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Aside;
