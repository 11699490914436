import { BoxProps, Container, Flex, HStack, IconProps, Text } from '@chakra-ui/react';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FaInstagramSquare } from '@react-icons/all-files/fa/FaInstagramSquare';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaVimeoSquare } from '@react-icons/all-files/fa/FaVimeoSquare';
import { FaYoutubeSquare } from '@react-icons/all-files/fa/FaYoutubeSquare';
import { FaSquareXTwitter } from 'react-icons/fa6';

import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER, VIMEO, YOUTUBE } from '../constants/urls';
import useSearchPhoneNumbers from '../hooks/useSearchPhoneNumbers';
import SocialMediaIconLink from './SocialMediaIconLink';
import { Link as ChakraLink } from './ui/links/Link';

interface FooterProps extends BoxProps {
  _socialMediaIcon?: IconProps;
}
const LPFooter = ({ _socialMediaIcon, ...props }: FooterProps) => {
  const phoneNumber = useSearchPhoneNumbers();

  return (
    <Flex
      bgColor="grayBackground"
      mx="auto"
      maxW="1600px"
      w="80%"
      minW="300px"
      h="180px"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      {...props}
    >
      <HStack pt={6} spacing={{ base: 5, md: 8, lg: 16 }} justifyContent="center">
        <SocialMediaIconLink
          icon={FaInstagramSquare}
          href={INSTAGRAM}
          ariaLabel="Lease End's Instagram Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaSquareXTwitter}
          href={TWITTER}
          ariaLabel="Lease End's Twitter Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaYoutubeSquare}
          href={YOUTUBE}
          ariaLabel="Lease End's Youtube Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaLinkedin}
          href={LINKEDIN}
          ariaLabel="Lease End's LinkedIn Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaFacebookSquare}
          href={FACEBOOK}
          ariaLabel="Lease End's Facebook Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaVimeoSquare}
          href={VIMEO}
          ariaLabel="Lease End's Vimeo Account"
          {..._socialMediaIcon}
        />
      </HStack>
      <Container maxW="container.xl">
        <Flex mb="0px" flexDir={{ base: 'column', md: 'row' }}>
          <Flex
            flexDirection="column"
            alignItems="center"
            paddingLeft={{ base: '0px !important', md: '30px !important' }}
            w="full"
            mt="8px"
          >
            <Text fontWeight="semibold">Give Us a Call:</Text>
            <ChakraLink
              fontWeight="bold"
              fontSize="2rem"
              to={`tel:+1-${phoneNumber}`}
              aria-label="Call US"
              color={props.color ?? 'leaseEndBlue'}
              _hover={{ color: 'white' }}
            >
              {phoneNumber}
            </ChakraLink>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default LPFooter;
